import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'antd';

import { ReloadOutlined } from '@ant-design/icons';

import { useNavigate } from 'react-router-dom';

import { getUser } from '../../../../redux/actions';
import MachinesListTable from './components/MachinesListTable';
import Header from '../../components/Header';
import RenderLayout from '../../components/RenderLayout';

function MachinesList() {
  const { isLoading, isFetched } = useSelector(state => state.user);
  const data = useSelector(state => state.user.data.machine);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isFetched) {
      dispatch(getUser());
    }
  }, [dispatch, isFetched]);
  return (
    <div>
      <Header
        title="Machines"
        rightComponent={(
          <>
            <Button
              type="success"
              icon={<ReloadOutlined />}
              onClick={() => {
                dispatch(getUser());
              }}
            />
            <Button
              type="primary"
              className="ml-auto mt-2"
              onClick={() => {
                navigate('/admin/machines/new');
              }}
            >
              Add Machine
            </Button>
          </>
        )}
      />
      <RenderLayout isFetched={isFetched} isLoading={isLoading}>
        <MachinesListTable data={data} />
      </RenderLayout>
    </div>
  );
}

export default MachinesList;
