import * as actionTypes from '../actions/type';

const INITIAL_STATE = {
  data: [],
  isLoading: false,
  isFetched: false,
};

export const types = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_TYPES:
      return { ...state, isLoading: true, isFetched: true };
    case actionTypes.GET_TYPES_SUCCESS:
      return { data: action.payload, isLoading: false, isFetched: true };
    default:
      return state;
  }
};
