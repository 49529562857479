import axios from 'axios';
import { call, put } from 'redux-saga/effects';

import { EXPRESS_API_URL } from '../../constants';
import { config, handleApiError } from '../utils';
import { GET_TIME_OPTIONS_SUCCESS, SET_ACTIVE_OPTION } from '../actions/type';

export function* getTimeOptionsRequest(action) {
  try {
    const { mid, sid, type, dataType } = action.payload;
    if (mid === undefined || sid === undefined || type === undefined || dataType === undefined) {
      return;
    }
    const res = yield call(
      axios.post,
      `${EXPRESS_API_URL}/chartData/timeOptions`,
      { mid, sid, type, dataType, includeMeasurements: type === 'timewave' ? 1 : 2 },
      config,
    );
    yield put({ type: GET_TIME_OPTIONS_SUCCESS, payload: res.data });
    yield put({ type: SET_ACTIVE_OPTION, selected: res.data.at(0).ReadingTimeUTC });
  }
  catch (error) {
    handleApiError(error, 'getTimeOptions');
  }
}
