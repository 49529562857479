import React from 'react';
import { Button, Table } from 'antd';

import { Link } from 'react-router-dom';

import { prepareAntDesignFilters } from '../../../utils';
import { DeleteUserForm } from '../Forms';

const UsersListTable = ({ data }) => {
  const filters = prepareAntDesignFilters(data, ['role', 'status']);
  filters.company = [...new Set(data.map(userData => userData.company.company))].map(
    company => ({ text: company, value: company }),
  );
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: 150,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 250,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      width: 150,
      filters: filters.role,
      onFilter: (value, record) => record.role === value,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      filters: filters.status,
      onFilter: (value, record) => record.status === value,
      render: item => String(item),
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      width: 150,
      render: item => item.company,
      filters: filters.company,
      onFilter: (value, record) => value === record.company.company,
    },
    {
      title: 'Action',
      key: 'operation',
      render: item => (
        <div className="flex gap-5">
          <Button type="primary">
            <Link to={`/admin/users/changePassword/${item._id}`}>Change Password</Link>
          </Button>
          <Button type="primary">
            <Link to={`/admin/users/update/${item._id}`}>Edit</Link>
          </Button>
          <DeleteUserForm data={item} />
        </div>
      ),
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={data.map((item, index) => ({ key: index, ...item }))}
      className="min-h-screen h-full"
      scroll={{ x: 1300 }}
    />
  );
};
export default UsersListTable;
