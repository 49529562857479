import * as actionTypes from '../actions/type';

export const riskindexesgraph = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_RISK_INDEXES_GRAPH_SUCCESS:
      return {
        ...state,
        [action.payload.mid]: { isLoading: false, isFetched: true, data: action.payload.data },
      };
    case actionTypes.GET_RISK_INDEXES_GRAPH:
      return { ...state, [action.payload.mid]: { isLoading: true, isFetched: true, data: [] } };
    default:
      return state;
  }
};
