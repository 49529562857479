import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import Header from '../../components/Header';

import { getUsersList } from '../../../../redux/actions';
import UsersListTable from './components/UsersListTable';
import RenderLayout from '../../components/RenderLayout';

function UsersList() {
  const { isFetched, isLoading, data } = useSelector(state => state.userslist);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isFetched) {
      dispatch(getUsersList());
    }
  }, [dispatch, isFetched]);
  return (
    <div>
      <Header
        title="Users"
        rightComponent={(
          <>
            <Button
              type="success"
              icon={<ReloadOutlined />}
              onClick={() => {
                dispatch(getUsersList());
              }}
            />
            <Button
              type="primary"
              className="ml-auto mt-2"
              onClick={() => {
                navigate('/admin/users/new');
              }}
            >
              Add User
            </Button>
          </>
        )}
      />
      <RenderLayout isFetched={isFetched} isLoading={isLoading}>
        <UsersListTable data={data} />
      </RenderLayout>
    </div>
  );
}

export default UsersList;
