import { harmonicGenerator } from './utils';

const HarmonicsElement = ({ x, y, value }) => (
  <g key={x}>
    <line key={x} x1={x} y1={y} x2={x} y2={y - 40} stroke="red" strokeWidth={1} />
    <text x={x} y={y} dy={-15} dx={10} stroke="#696969" fontSize={10} textAnchor="middle">
      {value}
    </text>
  </g>
);

export default function Harmonics({ selectedHarmonic, harmonicHandler, dataFactor, ...props }) {
  const activeValue = props?.activePayload?.[0].payload.x;
  const allPoints = props?.formattedGraphicalItems?.[0].props.points;
  if (allPoints?.length > 0) {
    let harmonicArray = [];
    let x = 0;
    let y = 0;
    const harmonics = [];
    allPoints.forEach(point => {
      const pointX = point.payload.x;
      if (pointX === activeValue) {
        harmonicArray = harmonicGenerator(activeValue);
      }
      if (selectedHarmonic) {
        if (
          selectedHarmonic.map(harmonic => harmonic.x).includes(Math.floor(pointX * dataFactor))
        ) {
          x = point.x;
          y = point.y;
          harmonics.push({ x, y, value: 'H' });
        }
      }
      else if (harmonicHandler && harmonicArray.includes(Math.round(pointX))) {
        x = point.x;
        y = point.y;
        if (
          !harmonics.find(harmonic => harmonic.value === `${Math.round(pointX / activeValue)}x`)
        ) {
          harmonics.push({ x, y, value: `${Math.round(pointX / activeValue)}x` });
        }
      }
    });
    return (
      <>
        {harmonics.map(item => (
          <HarmonicsElement x={item.x} y={item.y} value={item.value} key={item.x} />
        ))}
      </>
    );
  }
  return null;
}
