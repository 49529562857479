import * as actionTypes from '../actions/type';

const initialState = {
  isFetched: false,
  isLoading: false,
  data: [],
};

export const periodicrecordsgraph = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PERIODIC_RECORDS_GRAPH:
      return { ...state, isFetched: true, isLoading: true };
    case actionTypes.GET_PERIODIC_RECORDS_GRAPH_SUCCESS:
      return { ...state, isFetched: true, isLoading: false, data: action.payload };
    default:
      return state;
  }
};
