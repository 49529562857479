import React from 'react';
import { Table } from 'antd';

import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment/moment';

import { getLogs } from '../../../../../redux/actions';

const LogsListTable = ({ data }) => {
  const { totalCount, isLoading, filters } = useSelector(state => state.logs);
  const dispatch = useDispatch();

  const columns = [
    {
      title: 'Mid',
      dataIndex: 'mid',
      key: 'mid',
      fixed: 'left',
      width: 150,
    },
    {
      title: 'Sid',
      width: 100,
      dataIndex: 'sid',
      key: 'sid',
    },
    {
      title: 'Data Type',
      dataIndex: 'dataType',
      key: 'dataType',
      width: 150,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      width: 150,
    },
    {
      title: 'Sub Category',
      dataIndex: 'subCategory',
      key: 'category',
      width: 150,
    },
    {
      title: 'Entry',
      dataIndex: 'entry',
      key: 'entry',
      width: 150,
    },
    {
      title: 'CreatedAt',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      render: value => moment(value).format('YYYY-MM-DD HH:mm:ss'),
    },
  ];
  const paginationChanged = (...[page, itemCount]) => {
    const skip = (page - 1) * itemCount;
    const limit = itemCount;
    const currentFilters = { ...filters, skip, limit };
    dispatch(getLogs(currentFilters));
  };
  return (
    <Table
      columns={columns}
      loading={isLoading}
      dataSource={data.map((item, index) => ({ key: index, ...item }))}
      className="min-h-screen h-full"
      pagination={{ total: totalCount, onChange: paginationChanged }}
      scroll={{ x: 1300 }}
    />
  );
};
export default LogsListTable;
