import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';

import Header from '../../components/Header';

import { getLogs } from '../../../../redux/actions';
import LogsListTable from './components/LogsListTable';
import RenderLayout from '../../components/RenderLayout';
import FilterSearch from './components/FilterSearch';

function LogsList() {
  const dispatch = useDispatch();
  const { isFetched, data } = useSelector(state => state.logs);
  useEffect(() => {
    if (!isFetched) {
      dispatch(getLogs({ skip: 0, limit: 10, desc: true }));
    }
  }, [dispatch, isFetched]);
  return (
    <div>
      <Header
        title="Logs"
        rightComponent={(
          <Button
            type="success"
            icon={<ReloadOutlined />}
            onClick={() => {
              dispatch(getLogs({ skip: 0, limit: 10, desc: true }));
            }}
          />
        )}
      />
      <RenderLayout isFetched={isFetched}>
        <FilterSearch />
        <LogsListTable data={data} />
      </RenderLayout>
    </div>
  );
}

export default LogsList;
