import axios from 'axios';
import { call, put } from 'redux-saga/effects';

import { EXPRESS_API_URL } from '../../constants';
import { config, handleApiError } from '../utils';
import { GET_MULTIVARIATE_ANOMALIES_DATA_SUCCESS } from '../actions/type';

export function* getMultivariateAnomaliesDataRequest(action) {
  try {
    const { mid, type, dataType } = action.payload;
    if (mid === undefined || type === undefined || dataType === undefined) {
      return;
    }
    const res = yield call(
      axios.post,
      `${EXPRESS_API_URL}/chartData/multivariateAnomalies`,
      { mid, type, dataType },
      config,
    );
    const sensorType = type;
    yield put({
      type: GET_MULTIVARIATE_ANOMALIES_DATA_SUCCESS,
      payload: res.data,
      mid,
      dataType,
      sensorType,
    });
  }
  catch (error) {
    handleApiError(error, 'geMultivariateAnomaliesData');
  }
}
