import { Area, Line } from 'recharts';
import { useParams } from 'react-router-dom';

import Rechart from '../Rechart/Rechart';
import { yAxisLabelFormatter } from '../../utils';
import ColoredDot from './ColoredDot';

export default function TrendChart({ data, activeDataType, activeType }) {
  // Temporary
  let allData = data;
  const { machine } = useParams();
  if (machine === 'UF-1A' || machine === 'UF-2B_1') {
    allData = allData.filter(item => {
      const itemDate = new Date(item.x);
      const cutoffDate = new Date('2024-08-13');
      return itemDate >= cutoffDate;
    });
  }
  if (machine === 'UF-2B' || machine === 'UF-2C') {
    allData = allData.filter(item => {
      const itemDate = new Date(item.x);
      const cutoffDate = new Date('2024-08-13');
      return itemDate < cutoffDate;
    });
  }
  // Temporary
  const yValues = allData.reduce(
    (acc, item) => {
      if (item.y > acc.max_y || acc.max_y === undefined) {
        acc.max_y = item.y;
      }
      if (item.y < acc.min_y || acc.min_y === undefined) {
        acc.min_y = item.y;
      }
      acc.total_y += item.y;
      acc.count += 1;

      return acc;
    },
    { max_y: undefined, min_y: undefined, total_y: 0, count: 0 },
  );

  const average = yValues.total_y / yValues.count;
  let minValue = average / 2;
  let maxValue = average * 2;
  if (yValues.max_y > maxValue) {
    maxValue += (yValues.max_y - maxValue) * 1.5;
  }
  if (yValues.min_y < minValue) {
    minValue -= (minValue - yValues.min_y) * 2;
  }
  allData.reverse();

  const yAxisLabel = yAxisLabelFormatter(activeDataType, activeType);
  const trendOptions = {
    axisLabelisVisible: false,
    legendIsVisible: true,
    axisLabel: {
      x: 'Hz',
      y: yAxisLabel,
    },
    xAxis: {
      type: 'datetime',
      tickCount: 7,
      minValue: allData[0].x,
      maxValue: allData[allData.length - 1].x,
      decimalsInFloat: 0,
    },
    yAxis: {
      tickCount: 7,
      minValue: 0,
      maxValue,
      decimalsInFloat: 2,
    },
    chartType: activeType,
    timeRangeSelector: true,
    legendVerticalAlign: 'top',
  };
  return (
    <div>
      <Rechart data={allData} options={trendOptions}>
        <Area
          dataKey={m => {
            return [m.lowerMargins, m.upperMargins];
          }}
          stroke=""
          fill="#BCEAD5"
          fillOpacity={0.4}
          name="Margins"
          isAnimationActive={false}
        />
        <Line
          type="monotone"
          strokeWidth={2}
          stroke="#33b2df"
          isAnimationActive={false}
          dataKey="y"
          name={trendOptions.axisLabel.y}
          dot={<ColoredDot />}
        />
      </Rechart>
    </div>
  );
}
