import { Button, Card, Col, List, Row } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import { DeleteCompanyForm } from '../Forms';

function Cards() {
  const companies = useSelector(state => state.companies.data);
  const navigate = useNavigate();
  return (
    <Row gutter={16}>
      {companies.map(companyData => (
        <Col xs={24} sm={12} md={8} lg={8} xl={6} key={companyData._id}>
          <Card
            title={companyData.company}
            bordered={false}
            extra={(
              <div className="flex gap-2">
                <Button
                  onClick={() => {
                    navigate(`/admin/companies/update/${companyData._id}`);
                  }}
                >
                  <EditOutlined />
                </Button>
                <DeleteCompanyForm data={companyData} />
              </div>
            )}
          >
            <List
              size="small"
              bordered
              dataSource={companyData.factories}
              renderItem={item => <List.Item>{item}</List.Item>}
            />
          </Card>
        </Col>
      ))}
    </Row>
  );
}

export default Cards;
