import { EXPRESS_API_URL } from '../../constants';
import authHeader from '../../services/auth-header';

export const prepareAntDesignFilters = (data, keys) => {
  const filtersObject = {};
  keys.forEach(key => {
    filtersObject[key] = [...new Set(data.map(item => item[key]))].map(subKey => ({
      text: subKey,
      value: subKey,
    }));
  });
  return filtersObject;
};

export const uploadConfig = {
  action: `${EXPRESS_API_URL}/media`,
  headers: authHeader(),
};
