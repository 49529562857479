import tr from '../../../translations/tr.json';

export const getTimelineChartConfig = (timeline, machine) => {
  const timelineDates = timeline?.map(t => t.date);
  const timelineStatus = timeline?.map(t => t.alarm_status);
  const state = {
    series: [
      {
        name: '',
        data: timelineStatus,
      },
    ],
    options: {
      chart: {
        id: `machine${machine}`,
        type: 'heatmap',
        locales: [tr],
        defaultLocale: 'tr',
        toolbar: { show: false },
      },
      grid: {
        padding: {
          top: -30,
          right: 10,
          bottom: 0,
          left: 10,
        },
        show: false,
      },
      xaxis: {
        type: 'datetime',
        categories: timelineDates,
        labels: { style: { colors: '#FFFFFF' } },
      },
      plotOptions: {
        heatmap: {
          enableShades: false,
          radius: 0,
          useFillColorAsStroke: true,
          colorScale: {
            ranges: [
              {
                from: 0,
                to: 0,
                name: 'green',
                color: '#04CC66',
              },
              {
                from: 1,
                to: 1,
                name: 'yellow',
                color: '#FDE58B',
              },
              {
                from: 4,
                to: 4,
                name: 'grey',
                color: '#6B7280',
              },
            ],
          },
        },
      },
      dataLabels: { enabled: false },
      tooltip: { enabled: false },
      stroke: { width: 5 },
    },
  };
  return state;
};

export const getRiskIndexesChartConfig = highestValue => {
  const color = highestValue > 35 ? '#fff200' : 'rgba(39, 245, 67, 0.6)';

  const state = {
    series: [highestValue],
    options: {
      chart: {
        height: 350,
        type: 'radialBar',
      },
      plotOptions: {
        radialBar: {
          size: 500,
          track: { background: '#97AABD' },
          hollow: { size: '50%' },
          dataLabels: {
            name: {
              offsetY: -10,
              show: false,
            },
            value: {
              offsetY: 9,
              align: 'center',
              justify: 'center',
              show: true,
              color: 'white',
              fontWeight: 600,
              fontSize: '20px',
              formatter(val) {
                return `%${val == null ? ' - ' : val}`;
              },
            },
          },
          align: 'center',
          justify: 'center',
          radius: '100%',
        },
      },
      colors: [color],
      radius: '100%',
    },
  };
  return state;
};

export const getChartConfig = (chartData = [], machine) => {
  const [values, labels] = setChartConfigs(chartData);
  const state = {
    series: [
      {
        data: values,
        name: 'mm/s',
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'line',
        id: machine,
        locales: [tr],
        defaultLocale: 'tr',
        sparkline: { enabled: true },
      },
      noData: {
        text: 'Veriler Yükleniyor...',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: '#000000',
          fontSize: '14px',
          fontFamily: 'Helvetica',
        },
      },
      dataLabels: { enabled: false },
      stroke: {
        show: true,
        curve: 'straight',
        lineCap: 'butt',
        colors: undefined,
        width: 1,
        dashArray: 0,
      },
      grid: {
        padding: {
          right: 30,
          left: 20,
        },
      },
      labels,
      xaxis: { show: false },
      yaxis: { show: false },
    },
  };
  return state;
};

function setChartConfigs(chartData) {
  const values = [];
  const labels = [];
  const data = chartData?.trend?.series?.values;
  if (data !== undefined) {
    const reversedData = [...data]?.reverse();
    reversedData?.forEach(item => {
      values.push(item.y);
      labels.push(item.x);
    });
  }
  return [values, labels];
}

export const setNameForStatus = value => {
  if (value === 'DISABLED') return 'DISABLED';
  if (!value) return 'GOOD';
  if (value) return 'NOTICE';
  return '-------';
};

export const setClassForStatus = value => {
  if (!value) return 'bg-green';
  if (value) return 'bg-yellow';
  return 'bg-gray';
};
