import * as actionTypes from '../actions/type';

export const chat = (state = [], action) => {
  switch (action.type) {
    case actionTypes.GET_MESSAGES_SUCCESS:
      return { isLoading: false, chat: action.payload };
    case actionTypes.GET_MESSAGES:
      return { isLoading: true };
    default:
      return state;
  }
};
