/* eslint-disable no-bitwise */
export function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export const getHighestValue = data => {
  let highestValue = 0;
  Object?.values(data)?.forEach(obj => {
    Object?.values(obj)?.forEach(value => {
      if (value > highestValue) {
        highestValue = value ?? 0;
      }
    });
  });
  return highestValue;
};

export function generateColorGradient(baseColor, numColors, step) {
  function hexToRgb(hex) {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return { r, g, b };
  }
  function generateSimilarColor(baseRGB, stepRange) {
    const newColor = {
      r: clamp(baseRGB.r + stepRange, 0, 255),
      g: clamp(baseRGB.g + stepRange, 0, 255),
      b: clamp(baseRGB.b + stepRange, 0, 255),
    };

    return newColor;
  }

  function rgbToHex(r, g, b) {
    return `#${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1)}`;
  }

  function clamp(value, min, max) {
    return Math.min(Math.max(value, min), max);
  }

  const colors = [baseColor];
  const baseRGB = hexToRgb(baseColor);

  for (let i = 1; i < numColors; i++) {
    const newColor = generateSimilarColor(baseRGB, step * i);
    colors.push(rgbToHex(newColor.r, newColor.g, newColor.b));
  }

  return colors;
}
export const colorPalette = generateColorGradient('#4495e4', 7, 20);

export const yAxisLabelFormatter = (activeDataType, activeType) => {
  const labelMap = {
    RMS: {
      timewave: 'mm/s',
      default: 'mm/s rms',
    },
    ACC: {
      spectrum: 'g Ptp',
      timewave: 'g',
      default: 'g True Ptp',
    },
    ENV: {
      spectrum: 'gE Ptp',
      timewave: 'gE',
      default: 'gE True Ptp',
    },
    TEMP: '°C',
  };
  const typeMap = labelMap[activeDataType] || labelMap.RMS;
  return typeMap[activeType] || typeMap.default || typeMap;
};

export const createArrayForChart = (length, totalIndexes = 1000) => {
  const array = [];
  const divideNumber = totalIndexes / length;
  for (let index = 0; index < length; index++) {
    array[index] = divideNumber * index;
  }
  return array;
};

export const sortedSuborMachines = ['E1_1 Winder', 'E1_2 Winder', 'CW4000 Winder', 'NC Winder',
  'ERS 55', 'ERS 37', 'RSN 75E', 'FITTING KOMPRESÖR 1', 'FITTING KOMPRESÖR 2', 'Gelecek Makine'];

export const sortedAksaMachines = ['12-Q9', '12-Q10'];

export const sortedIstacMachines = ['UF-1A', 'UF-1D', 'UF-2A', 'UF-2B_1', 'UF-2D', 'UF-2B', 'UF-2C',
  'Gelecek Makine', 'Gelecek Makine', 'Gelecek Makine'];
