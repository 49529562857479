import React, { useEffect, useState } from 'react';
import { Button, Form, InputNumber, Select, Switch, DatePicker } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { getLogs, getUser } from '../../../../../redux/actions';
import { dataTypes, logsCategories } from '../../../constants';

const FilterSearch = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { isFetched, isLoading } = useSelector(state => state.user);
  const [selectedCategory, setSelectedCategory] = useState();
  const machinesList = useSelector(state => state.user.data.machine).map(
    machine => machine?.mid,
  );
  useEffect(() => {
    if (!isFetched) {
      dispatch(getUser());
    }
  }, [dispatch, isFetched]);

  const handleOk = () => {
    form.validateFields().then(values => {
      const filteredValues = Object.fromEntries(
        // eslint-disable-next-line no-unused-vars
        Object.entries(values).filter(([_, v]) => v !== undefined && v !== null && v !== ''),
      );
      if (filteredValues.range) {
        filteredValues.createdAtAfter = filteredValues.range[0].$d.toString();
        filteredValues.createdAtBefore = filteredValues.range[1].$d.toString();
        delete filteredValues.range;
      }
      dispatch(getLogs({ skip: 0, limit: 10, ...filteredValues }));
    });
  };
  const handleCategorySelect = e => {
    setSelectedCategory(e);
  };
  return (
    <div className="flex">
      <Form
        form={form}
        name="filterSearchForm"
        labelCol={{ span: 32 }}
        layout="horizontal"
        wrapperCol={{ span: 32 }}
        className="w-100 flex gap-5 flex-wrap lg:flex-row flex-col"
        initialValues={{ desc: true, ...dataTypes }}
        autoComplete="off"
        onFinish={handleOk}
      >
        <Form.Item label="Machine" name="mid" required>
          <Select
            style={{ width: 220 }}
            options={machinesList?.map(mid => ({ label: mid, value: mid }))}
            loading={isLoading}
          />
        </Form.Item>
        <Form.Item label="Sensor Id" name="sid" required>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Data Type" name="dataType">
          <Select
            style={{ width: 150 }}
            placeholder="RMS, ENV"
            options={dataTypes.map(dataType => ({ label: dataType, value: dataType }))}
          />
        </Form.Item>
        <Form.Item label="Category" name="category">
          <Select
            style={{ width: 150 }}
            options={Object.keys(logsCategories).map(category => ({
              label: category,
              value: category,
            }))}
            onChange={handleCategorySelect}
          />
        </Form.Item>
        <Form.Item label="Sub Category" name="subCategory">
          <Select
            style={{ width: 150 }}
            options={logsCategories[selectedCategory]?.map(subCategory => ({
              label: subCategory,
              value: subCategory,
            }))}
          />
        </Form.Item>
        <Form.Item label="Desc" name="desc" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item label="RangePicker" name="range">
          <DatePicker.RangePicker />
        </Form.Item>
        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default FilterSearch;
