import React from 'react';

import { ChartSkeleton } from '../../shared/Skeletons';
import TrendChart from './Charts/TrendChart';
import SpectrumChart from './Charts/SpectrumChart';
import TimeWaveChart from './Charts/TimeWaveChart';
import TrendHeatChart from './Charts/TrendHeatChart';
import MultipleTrendChart from './Charts/MultipleCharts/MultipleTrendChart';
import MultipleTempChart from './Charts/MultipleCharts/MultipleTempChart';

export function MultipleChart({ series, activeDataType }) {
  const chartType = activeDataType === 'TEMP' ? 'heat' : 'trend';
  const ChartComponent = { trend: MultipleTrendChart, heat: MultipleTempChart }[chartType];
  if (!ChartComponent) return <ChartSkeleton />;
  return (
    <ChartComponent
      data={series}
      chartType={chartType}
      activeDataType={activeDataType}
    />
  );
}

export function Chart({ data, machine, activeType, activeDataType, annotations }) {
  const ChartComponent = {
    trend: TrendChart,
    spectrum: SpectrumChart,
    timewave: TimeWaveChart,
    heat: TrendHeatChart,
  }[activeType];
  if (!ChartComponent) return <ChartSkeleton />;
  const preparedData = data[activeType].series.values.map(item => ({
    ...item,
    x: new Date(item.x).getTime(),
  }));
  let xAxisMax;
  if (activeType === 'spectrum') {
    xAxisMax = data.spectrum.series.EndFrequency;
  }
  else if (activeType === 'timewave') {
    xAxisMax = data.timewave.series.DurationOfMeasurements;
  }
  return (
    <ChartComponent
      data={preparedData}
      machine={machine}
      activeDataType={activeDataType}
      activeType={activeType}
      annotations={annotations}
      xAxisMax={Number(xAxisMax)}
      handledHarmonics={activeType === 'spectrum' ? data.spectrum.harmonics : null}
    />
  );
}
