import { Line } from 'recharts';

import moment from 'moment';

import { useMemo } from 'react';

import Rechart from '../Rechart/Rechart';

export default function RpmHistoryChart({ data }) {
  const preparedData = useMemo(() => data.map(item => ({
    x: moment.utc(item.x).toDate().getTime(),
    y: item.y,
  })), [data]);
  const options = {
    height: 300,
    axisLabelisVisible: false,
    legendIsVisible: false,
    xAxis: {
      type: 'datetime',
      tickCount: 7,
      minValue: preparedData[0].x,
      maxValue: preparedData[preparedData.length - 1].x,
      decimalsInFloat: 1,
    },
    yAxis: {
      tickCount: 7,
      minValue: 0,
      maxValue: preparedData.sort((a, b) => b.y - a.y)[0].y * 1.1,
      decimalsInFloat: 0,
    },
  };
  return (
    <Rechart data={preparedData} options={options}>
      <Line
        dataKey="y"
        isAnimationActive={false}
        name="RPM"
        stroke="none"
        dot={{ stroke: '#e5e7eb', fill: '#e5e7eb', strokeWidth: 2 }}
        activeDot={{ stroke: '#e5e7eb', strokeWidth: 6, fill: '#e5e7eb' }}
      />
    </Rechart>
  );
}
