export const GET_SENSORS_DATA = 'GET_SENSORS_DATA';
export const GET_SENSORS_DATA_SUCCESS = 'GET_SENSORS_DATA_SUCCESS';

export const GET_CHART_DATA = 'GET_CHART_DATA';
export const GET_CHART_DATA_SUCCESS = 'GET_CHART_DATA_SUCCESS';
export const GET_CHART_DATA_FAILURE = 'GET_CHART_DATA_FAILURE';

export const GET_MULTIVARIATE_ANOMALIES_DATA = 'GET_MULTIVARIATE_ANOMALIES_DATA';
export const GET_MULTIVARIATE_ANOMALIES_DATA_SUCCESS = 'GET_MULTIVARIATE_ANOMALIES_DATA_SUCCESS';
export const GET_MULTIVARIATE_ANOMALIES_DATA_FAILURE = 'GET_MULTIVARIATE_ANOMALIES_DATA_FAILURE';

export const GET_TYPES = 'GET_TYPES';
export const GET_TYPES_SUCCESS = 'GET_TYPES_SUCCESS';
export const GET_TYPES_FAILURE = 'GET_TYPES_FAILURE';

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';

export const GET_TIME_OPTIONS = 'GET_TIME_OPTIONS';
export const GET_TIME_OPTIONS_SUCCESS = 'GET_TIME_OPTIONS_SUCCESS';
export const SET_ACTIVE_OPTION = 'SET_ACTIVE_OPTION';

export const GET_STATE_HISTORY = 'GET_STATE_HISTORY';
export const GET_STATE_HISTORY_SUCCESS = 'GET_STATE_HISTORY_SUCCESS';
export const GET_STATE_HISTORY_FAILURE = 'GET_STATE_HISTORY_FAILURE';

export const GET_USER_DATA = 'GET_USER_DATA';
export const GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS';

export const GET_MACHINE_DATA = 'GET_MACHINE_DATA';
export const GET_MACHINE_DATA_SUCCESS = 'GET_MACİNE_DATA_SUCCESS';

export const GET_MESSAGES = 'GET_MESSAGES';
export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS';

export const SEND_MESSAGE = 'SEND_MESSAGE';

export const GET_ANNOTATIONS = 'GET_ANNOTATIONS';
export const GET_ANNOTATIONS_SUCCESS = 'GET_ANNOTATIONS_SUCCESS';
export const SHOW_HIDE_ANNOTATIONS = 'SHOW_HIDE_ANNOTATIONS';

export const GET_LIVE_STATUS = 'GET_LIVE_STATUS';
export const GET_LIVE_STATUS_SUCCESS = 'GET_LIVE_STATUS_SUCCESS';

export const SAVE_LOG = 'SAVE_LOG';
export const SAVE_LOG_SUCCESS = 'SAVE_LOG_SUCCESS';

export const GET_LOGS = 'GET_LOGS';
export const GET_LOGS_SUCCESS = 'GET_LOGS_SUCCESS';

export const GET_RISK_INDEXES = 'GET_RISK_INDEXES';
export const GET_RISK_INDEXES_SUCCESS = 'GET_RISK_INDEXES_SUCCESS';
export const GET_RISK_INDEXES_FAILURE = 'GET_RISK_INDEXES_FAILURE';

export const GET_RISK_INDEXES_GRAPH = 'GET_RISK_INDEXES_GRAPH';
export const GET_RISK_INDEXES_GRAPH_SUCCESS = 'GET_RISK_INDEXES_GRAPH_SUCCESS';

export const GET_ACTIVE_DATA = 'GET_ACTIVE_DATA';
export const SET_ACTIVE_SENSOR = 'SET_ACTIVE_SENSOR';
export const SET_ACTIVE_TYPE = 'SET_ACTIVE_TYPE';
export const SET_ACTIVE_DATATYPE = 'SET_ACTIVE_DATATYPE';

export const GET_RPM_HISTORY_GRAPH = 'GET_RPM_HISTORY_GRAPH';
export const GET_RPM_HISTORY_GRAPH_SUCCESS = 'GET_RPM_HISTORY_GRAPH_SUCCESS';

export const GET_COMPANIES = 'GET_COMPANIES';
export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS';

export const GET_SENSORS = 'GET_SENSORS';
export const GET_SENSORS_SUCCESS = 'GET_SENSORS_SUCCESS';

export const GET_PERIODIC_RECORDS_GRAPH = 'GET_PERIODIC_RECORDS_GRAPH';
export const GET_PERIODIC_RECORDS_GRAPH_SUCCESS = 'GET_PERIODIC_RECORDS_GRAPH_SUCCESS';

export const GET_POINTIDS_LIST = 'GET_POINTIDS_LIST';
export const GET_POINTIDS_LIST_SUCCESS = 'GET_POINTIDS_LIST_SUCCESS';

export const GET_USERS_LIST = 'GET_USERS_LIST';
export const GET_USERS_LIST_SUCCESS = 'GET_USERS_LIST_SUCCESS';

export const GET_ANNOTATIONS_LIST = 'GET_ANNOTATIONS_LIST';
export const GET_ANNOTATIONS_LIST_SUCCESS = 'GET_ANNOTATIONS_LIST_SUCCESS';

export const GET_GATEWAYS_LIST = 'GET_GATEWAYS_LIST';
export const GET_GATEWAYS_LIST_SUCCESS = 'GET_GATEWAYS_LIST_SUCCESS';
