import { DATA_TYPES, TYPES } from './constants';

export const dataTypes = {
  [DATA_TYPES.RMS]: {
    unit: ' mm/s',
    title: 'VIBRATION_SPEED',
    xaxisTitle: {
      [TYPES.TREND]: '',
      [TYPES.SPECTRUM]: 'Hz',
      [TYPES.TIMEWAVE]: 'Saniye',
    },
    yaxisTitle: {
      [TYPES.TREND]: 'mm/s rms',
      [TYPES.SPECTRUM]: 'mm/s rms',
      [TYPES.TIMEWAVE]: 'mm/s',
    },
    decimalsInFloat: 2,
    toFixed: 2,
  },
  [DATA_TYPES.ACC]: {
    unit: ' g PtP',
    title: 'VIBRATION_ACCELERATION',
    xaxisTitle: {
      [TYPES.TREND]: '',
      [TYPES.SPECTRUM]: 'Hz',
      [TYPES.TIMEWAVE]: 'Saniye',
    },
    yaxisTitle: {
      [TYPES.TREND]: 'g True PtP',
      [TYPES.SPECTRUM]: 'g Ptp',
      [TYPES.TIMEWAVE]: 'g',
    },
    decimalsInFloat: 0,
    toFixed: 2,
  },
  [DATA_TYPES.ENV]: {
    unit: ' gE PtP',
    title: 'Envelope gE',
    xaxisTitle: {
      [TYPES.TREND]: '',
      [TYPES.SPECTRUM]: 'Hz',
      [TYPES.TIMEWAVE]: 'Saniye',
    },
    yaxisTitle: {
      [TYPES.TREND]: 'gE True PtP',
      [TYPES.SPECTRUM]: 'gE PtP',
      [TYPES.TIMEWAVE]: 'gE',
    },
    decimalsInFloat: 3,
    toFixed: 2,
  },
  [DATA_TYPES.TEMP]: {
    unit: ' °C',
    title: 'TEMPERATURE',
    xaxisTitle: { [TYPES.HEAT]: '' },
    yaxisTitle: { [TYPES.HEAT]: '°C' },
    toFixed: 0,
  },
};

export const types = {
  [TYPES.TREND]: {
    xaxis: {
      type: 'datetime',
      title: { text: 'Tarih' },
    },
    yaxis: { decimalsInFloat: 2 },
  },
  [TYPES.SPECTRUM]: {
    xaxis: {
      type: 'numeric',
      title: { text: 'Hz' },
    },
    yaxis: {
      max(max) {
        return parseFloat(max * 2);
      },
      min: 0,
      decimalsInFloat: 3,
    },
  },
  [TYPES.TIMEWAVE]: {
    xaxis: {
      type: 'numeric',
      title: { text: 'Saniye' },
      min: 0,
      max: 3.2,
    },
    yaxis: {
      max(max) {
        return parseFloat(max * 2);
      },
      min(min) {
        return parseFloat(min * 2);
      },
      decimalsInFloat: 2,
    },
  },
  [TYPES.HEAT]: {
    xaxis: {
      type: 'datetime',
      title: { text: 'Tarih' },
    },
    yaxis: {
      max: 80,
      min: 0,
      title: { text: 'C°' },
      decimalsInFloat: 0,
    },
    name: 'C°',
  },
};
