import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { authGetRequest } from '../../../../services/api-service';
import { EditMachineForm } from './Forms';
import { EXPRESS_IMAGES_URL } from '../../../../constants';

function MachineUpdate() {
  const { id } = useParams();
  const [editData, setEditData] = useState({});

  const fetchData = useCallback(async () => {
    const response = await authGetRequest(`machines/${id}`);
    const machine = response.data;
    const updatedImages = machine.images.map(image => {
      return {
        ...image,
        path: `${EXPRESS_IMAGES_URL}/${image.path}`,
      };
    });
    machine.images = updatedImages;
    setEditData(machine);
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  return <div>{editData.mid && <EditMachineForm editData={editData} />}</div>;
}

export default MachineUpdate;
