import { Skeleton } from 'antd';
import React from 'react';

function RenderLayout({ isFetched, isLoading, children }) {
  return (
    <>
      {isLoading && <Skeleton active />}
      {!isLoading && isFetched && children}
    </>
  );
}

export default RenderLayout;
