import * as actionTypes from '../actions/type';

const INITIAL_STATE = {
  data: {
    user: {
      role: '',
      status: true,
      _id: '',
      name: '',
      email: '',
      company: '',
      createdAt: '',
      updatedAt: '',
    },
    machine: [
      {
        company: '',
        factory: '',
        mid: '',
        rpm: 0,
        visibleName: '',
        anomalyExcludeThreshold: 0,
      },
    ],
  },
  isLoading: false,
  isFetched: false,
};

export const user = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_DATA:
      return { ...state, isFetched: true, isLoading: true };
    case actionTypes.GET_USER_DATA_SUCCESS:
      return { ...state, data: action.payload, isLoading: false, isFetched: true };
    default:
      return state;
  }
};
