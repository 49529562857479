import { FormControlLabel, Switch } from '@mui/material';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { showHideAnnotations } from '../../../redux/actions';

function AnnotationSwitch() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleChange = e => {
    const isChecked = e.target.checked;
    dispatch(showHideAnnotations(isChecked));
  };

  return (
    <FormControlLabel
      control={<Switch onChange={handleChange} />}
      label={t('SHOW_HIDE_ANNOTATIONS')}
    />
  );
}

export default AnnotationSwitch;
