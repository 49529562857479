import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { getRiskIndexesGraph } from '../../../redux/actions';
import { ChartSkeleton } from '../../shared/Skeletons';
import RiskTrendChart from './Charts/RiskTrendChart';

function RiskOverTime() {
  const { machine } = useParams();
  const dispatch = useDispatch();
  const riskindexesgraph = useSelector(state => state?.riskindexesgraph);
  const data = riskindexesgraph[machine]?.data;
  const isLoading = riskindexesgraph[machine]?.isLoading;

  useEffect(() => {
    dispatch(getRiskIndexesGraph(machine));
  }, [dispatch, machine]);

  if (isLoading || !data) {
    return <ChartSkeleton />;
  }
  return (
    <div className="col-span-4 ml-4 mt-5 flex bg-white border rounded-lg flex-col md:max-w-xl dark:border-gray-700 dark:bg-gray-800 h-80">
      <div className="grid grid-cols-1">
        <div className="grid grid-cols-3">
          <div className="px-2 pt-2 col-span-12 text-lg font-bold tracking-tight text-gray-900 dark:text-white">
            Risk Trend
          </div>
        </div>
      </div>
      <div id="area-datetime" className="py-0 px-1  w-full h-full">
        <RiskTrendChart data={data} />
      </div>
    </div>
  );
}

export default RiskOverTime;
