import axios from 'axios';
import { call, put } from 'redux-saga/effects';

import { EXPRESS_API_URL } from '../../constants';
import { config, handleApiError } from '../utils';
import { GET_STATE_HISTORY_FAILURE, GET_STATE_HISTORY_SUCCESS } from '../actions/type';

export function* getStateHistoryRequest(action) {
  try {
    const { mid } = action.payload;
    const res = yield call(
      axios.post,
      `${EXPRESS_API_URL}/riskMeasurements/history`,
      { mid },
      config,
    );
    yield put({ type: GET_STATE_HISTORY_SUCCESS, payload: res.data });
  }
  catch (error) {
    yield put({ type: GET_STATE_HISTORY_FAILURE, payload: error });
    handleApiError(error, 'getStateHistory');
  }
}
