import { DataGrid, GridToolbar, trTR } from '@mui/x-data-grid';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getLogs } from '../../../redux/actions';

function DataTable() {
  const dispatch = useDispatch();
  const logs = useSelector(state => state.logs);
  const columns = [
    { field: 'id', headerName: 'ID', hide: true, headerAlign: 'center', flex: 1 },
    { field: 'log', headerName: 'Log', headerAlign: 'center', flex: 2, align: 'center' },
    { field: 'machine', headerName: 'Makine', headerAlign: 'center', flex: 1 / 2, align: 'center' },
    { field: 'fullname', headerName: 'Ad Soyad', headerAlign: 'center', flex: 1, align: 'center' },
    { field: 'email', headerName: 'Email', headerAlign: 'center', flex: 3 / 2, align: 'center' },
    {
      field: 'company_name',
      headerName: 'Şirket Adı',
      headerAlign: 'center',
      flex: 2,
      align: 'center',
    },
    {
      field: 'department',
      headerName: 'Departman',
      headerAlign: 'center',
      flex: 1,
      align: 'center',
    },
    { field: 'phone', headerName: 'Telefon', headerAlign: 'center', flex: 1, align: 'center' },
    { field: 'isAdmin', headerName: 'Admin', headerAlign: 'center', flex: 1 / 2, align: 'center' },
    {
      field: 'created_date',
      headerName: 'Tarih',
      headerAlign: 'center',
      flex: 3 / 2,
      align: 'center',
    },
  ];

  useEffect(() => {
    dispatch(getLogs());
  }, [dispatch]);

  return (
    <div className="h-full">
      <p className="text-black-900 text-4xl text-center p-2 border rounded-t-lg border-b-0 font-bold">
        Log Kayıtları
      </p>
      <DataGrid
        sx={{ align: 'center' }}
        align="center"
        rows={logs}
        columns={columns}
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
      />
    </div>
  );
}

export default DataTable;
