import * as actionTypes from '../actions/type';

export const statehistory = (state = [], action) => {
  switch (action.type) {
    case actionTypes.GET_STATE_HISTORY: {
      return { isLoading: true, stateHistory: [] };
    }
    case actionTypes.GET_STATE_HISTORY_SUCCESS:
      return { isLoading: false, stateHistory: action.payload };
    default:
      return state;
  }
};
