import React from 'react';
import { Button, Table } from 'antd';

import { Link } from 'react-router-dom';

import { prepareAntDesignFilters } from '../../../utils';
import { DeleteAnnotationForm } from '../Forms';

const AnnotationsListTable = ({ data }) => {
  const filters = prepareAntDesignFilters(data, [
    'mid',
    'pieceName',
    'frequencyType',
    'faultFrequency',
  ]);
  const columns = [
    {
      title: 'Mid',
      dataIndex: 'mid',
      key: 'mid',
      width: 150,
      filters: filters.mid,
      onFilter: (value, record) => record.mid === value,
    },
    {
      title: 'Piece Name',
      width: 100,
      dataIndex: 'pieceName',
      key: 'pieceName',
      filters: filters.pieceName,
      onFilter: (value, record) => record.pieceName === value,
    },
    {
      title: 'Frequency Type',
      dataIndex: 'frequencyType',
      key: 'frequencyType',
      width: 150,
      filters: filters.frequencyType,
      onFilter: (value, record) => record.frequencyType === value,
    },
    {
      title: 'Fault Frequency',
      dataIndex: 'faultFrequency',
      key: 'faultFrequency',
      width: 150,
      filters: filters.faultFrequency,
      onFilter: (value, record) => record.faultFrequency === value,
    },
    {
      title: 'Off Set Y',
      dataIndex: 'offsetY',
      key: 'offsetY',
      width: 150,
    },
    {
      title: 'Action',
      key: 'operation',
      width: 100,
      render: item => {
        return (
          <div className="flex gap-2">
            <Button>
              <Link to={`/admin/annotations/update/${item._id}`}>Edit</Link>
            </Button>
            <DeleteAnnotationForm data={item} />
          </div>
        );
      },
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={data.map((item, index) => ({ key: index, ...item }))}
      className="min-h-screen h-full"
      scroll={{ x: 1300 }}
    />
  );
};
export default AnnotationsListTable;
