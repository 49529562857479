import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import HPlusMobiledataIcon from '@mui/icons-material/HPlusMobiledata';
import HMobiledataIcon from '@mui/icons-material/HMobiledata';
import { Tooltip as MuiTooltip } from '@mui/material';

import { generateXAxisValues } from './utils';
import TimeRange from './TimeRange';

export default function ChartControlMenu({
  state,
  setState,
  xAxis,
  showHarmonics,
  harmonicSettings,
  setHarmonicSettings,
  timeRangeSelector,
  handledHarmonics,
  hideZoomReset,
  minimumTimeRangeKey,
}) {
  const zoomReset = () => {
    setState(prevState => ({
      ...prevState,
      refAreaLeft: '',
      refAreaRight: '',
      xTicks: generateXAxisValues([xAxis.minValue, xAxis.maxValue], '', '', xAxis.tickCount),
      left: 'dataMin',
      right: 'dataMax',
    }));
  };
  const toggleHarmonics = () => {
    const keys = Object.keys(harmonicSettings);
    const tempHarmonicSettings = {};
    keys.forEach(key => {
      tempHarmonicSettings[key] = false;
    });
    tempHarmonicSettings.harmonicHandler = !harmonicSettings?.harmonicHandler;
    setHarmonicSettings(tempHarmonicSettings);
  };
  const toggleHandledHarmonics = harmonic => {
    const keys = Object.keys(harmonicSettings);
    const tempHarmonicSettings = {};
    keys.forEach(key => {
      tempHarmonicSettings[key] = false;
    });
    tempHarmonicSettings[harmonic[0]._id] = !harmonicSettings[harmonic[0]._id];
    setHarmonicSettings(tempHarmonicSettings);
  };
  return (
    <div className="ml-auto mr-5 flex gap-1">
      {showHarmonics && (
        <MuiTooltip title="Harmonik Görüntüleyici Aç/Kapat">
          <HPlusMobiledataIcon
            fontSize="small"
            sx={{
              color: harmonicSettings.harmonicHandler ? 'white' : '',
              background: harmonicSettings.harmonicHandler ? '#33b2df' : '',
            }}
            onClick={toggleHarmonics}
            className="rounded cursor-pointer"
          />
        </MuiTooltip>
      )}
      {handledHarmonics?.length > 0 &&
        handledHarmonics.map(harmonic => (
          <MuiTooltip
            title={`Tespit Edilen: ${harmonic.map(item => Math.round(item.x / 3.2)).join('-')}`}
            key={harmonic._id}
          >
            <HMobiledataIcon
              fontSize="small"
              sx={{ color: 'white', background: 'red' }}
              onClick={() => toggleHandledHarmonics(harmonic)}
              className="rounded cursor-pointer"
              key={harmonic._id}
            />
          </MuiTooltip>
        ))}
      {timeRangeSelector && xAxis.minValue && (
        <TimeRange
          yTicks={state.yTicks}
          setState={setState}
          xAxis={xAxis}
          minimumTimeRangeKey={minimumTimeRangeKey}
        />
      )}
      {!hideZoomReset && (
        <MuiTooltip title="Bütün Veri">
          <ZoomOutMapIcon fontSize="small" onClick={zoomReset} className="cursor-pointer my-auto" />
        </MuiTooltip>
      )}
    </div>
  );
}
