import axios from 'axios';
import { call, put } from 'redux-saga/effects';

import { EXPRESS_API_URL } from '../../constants';
import { config, handleApiError } from '../utils';
import { GET_LIVE_STATUS_SUCCESS } from '../actions/type';

export function* getLiveStatusRequest(action) {
  try {
    const { mid } = action.payload;
    const res = yield call(
      axios.post,
      `${EXPRESS_API_URL}/trendMeasurements/liveStatus`,
      { mid },
      config,
    );
    yield put({ type: GET_LIVE_STATUS_SUCCESS, payload: res.data, mid });
  }
  catch (error) {
    handleApiError(error, 'getLiveStatus');
  }
}
