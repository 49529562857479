import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Skeleton, Table } from 'antd';

import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import { getGatewaysList } from '../../../../../redux/actions';

function Gateways() {
  const dispatch = useDispatch();
  const { isLoading, isFetched, data } = useSelector(state => state.gateways);
  useEffect(() => {
    if (!isFetched) {
      dispatch(getGatewaysList());
    }
  }, [dispatch, isFetched]);
  if (isLoading || !data) {
    return <Skeleton active />;
  }
  const tableColumns = [
    {
      title: 'Name',
      dataIndex: 'Name',
      key: 'Name',
    },
    {
      title: 'Location String',
      dataIndex: 'LocationString',
      key: 'LocationString',
    },
    {
      title: 'Internal Temperature',
      dataIndex: 'InternalTemperature',
      key: 'InternalTemperature',
    },
    {
      title: 'Connected',
      dataIndex: 'Connected',
      key: 'Connected',
      render: value => (value === 0 ? <CloseOutlined /> : <CheckOutlined />),
    },
  ];
  return (
    <div className="mt-10">
      <h1 className="text-2xl font-bold">Gateways</h1>
      <Table dataSource={data.map((item, index) => ({ key: index, ...item }))} columns={tableColumns} scroll={{ x: 1300 }} pagination={false} />
    </div>
  );
}

export default Gateways;
