import { all, takeEvery } from 'redux-saga/effects';

import {
  GET_ANNOTATIONS,
  GET_ANNOTATIONS_LIST,
  GET_CHART_DATA,
  GET_COMPANIES,
  GET_GATEWAYS_LIST,
  GET_LIVE_STATUS,
  GET_LOGS,
  GET_MACHINE_DATA,
  GET_MESSAGES,
  GET_MULTIVARIATE_ANOMALIES_DATA,
  GET_PERIODIC_RECORDS_GRAPH,
  GET_POINTIDS_LIST,
  GET_RISK_INDEXES,
  GET_RISK_INDEXES_GRAPH,
  GET_RPM_HISTORY_GRAPH,
  GET_SENSORS,
  GET_SENSORS_DATA,
  GET_STATE_HISTORY,
  GET_TIME_OPTIONS,
  GET_TYPES,
  GET_USER_DATA,
  GET_USERS_LIST,
  LOGIN,
  LOGOUT,
  SAVE_LOG,
  SEND_MESSAGE,
} from '../actions/type';
import { getAnnotationsRequest } from './annotations';
import { getMessagesRequest, sendMessageRequest } from './messages';
import { getChartDataRequest } from './chartData';
import { getMultivariateAnomaliesDataRequest } from './multivariateAnomalies';
import { getLiveStatusRequest } from './liveStatus';
import { getUserRequest, loginRequest, logout } from './user';
import { getMachinesRequest } from './machines';
import { getStateHistoryRequest } from './stateHistory';
import { getChartTypesRequest } from './chartTypes';
import { getTimeOptionsRequest } from './timeOptions';
import { getRiskIndexesGraphRequest, getRiskIndexesRequest } from './riskIndexes';
import { getLogsRequest, saveLogRequest } from './logs';
import { getSensorsDataRequest } from './sensorsData';
import { getRpmHistoryGraphRequest } from './rpmHistory';
import {
  getAnnotationsListRequest,
  getCompaniesListRequest,
  getGatewaysListRequest,
  getPointIdsListRequest,
  getSensorsListRequest,
  getUsersListRequest,
} from './lists';
import { getPeriodicRecordsGraphRequest } from './periodicRecords';

export default function* rootSaga() {
  yield all([
    takeEvery(GET_MESSAGES, getMessagesRequest),
    takeEvery(SEND_MESSAGE, sendMessageRequest),
    takeEvery(GET_CHART_DATA, getChartDataRequest),
    takeEvery(GET_MULTIVARIATE_ANOMALIES_DATA, getMultivariateAnomaliesDataRequest),
    takeEvery(GET_LIVE_STATUS, getLiveStatusRequest),
    takeEvery(GET_USER_DATA, getUserRequest),
    takeEvery(GET_MACHINE_DATA, getMachinesRequest),
    takeEvery(GET_STATE_HISTORY, getStateHistoryRequest),
    takeEvery(GET_TYPES, getChartTypesRequest),
    takeEvery(GET_TIME_OPTIONS, getTimeOptionsRequest),
    takeEvery(GET_ANNOTATIONS, getAnnotationsRequest),
    takeEvery(GET_RISK_INDEXES, getRiskIndexesRequest),
    takeEvery(GET_RISK_INDEXES_GRAPH, getRiskIndexesGraphRequest),
    takeEvery(LOGIN, loginRequest),
    takeEvery(LOGOUT, logout),
    takeEvery(SAVE_LOG, saveLogRequest),
    takeEvery(GET_LOGS, getLogsRequest),
    takeEvery(GET_SENSORS_DATA, getSensorsDataRequest),
    takeEvery(GET_RPM_HISTORY_GRAPH, getRpmHistoryGraphRequest),
    takeEvery(GET_COMPANIES, getCompaniesListRequest),
    takeEvery(GET_SENSORS, getSensorsListRequest),
    takeEvery(GET_PERIODIC_RECORDS_GRAPH, getPeriodicRecordsGraphRequest),
    takeEvery(GET_POINTIDS_LIST, getPointIdsListRequest),
    takeEvery(GET_USERS_LIST, getUsersListRequest),
    takeEvery(GET_ANNOTATIONS_LIST, getAnnotationsListRequest),
    takeEvery(GET_GATEWAYS_LIST, getGatewaysListRequest),
  ]);
}
