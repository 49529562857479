import { useEffect, useState } from 'react';

import {
  CartesianGrid,
  ComposedChart,
  Customized,
  Label,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
} from 'recharts';

import {
  formatFloat,
  generateXAxisValues,
  generateYAxisValues,
  tickFormatter,
  tooltipFormatter,
  tooltipLabelFormatter,
} from './utils';
import ChartControlMenu from './ChartControlMenu';
import HarmonicIndicator from './HarmonicIndicator';
import { ChartSkeleton } from '../../../shared/Skeletons';

const initial = (data, refAreaLeft, refAreaRight, xAxis, yAxis) => ({
  data,
  xTicks: generateXAxisValues(
    [xAxis.minValue, xAxis.maxValue],
    refAreaLeft,
    refAreaRight,
    xAxis.tickCount,
  ),
  yTicks: generateYAxisValues([yAxis.minValue, yAxis.maxValue], yAxis.tickCount),
  left: 'dataMin',
  right: 'dataMax',
  refAreaLeft: '',
  refAreaRight: '',
  animation: true,
});
const Rechart = ({ data, options, handledHarmonics = [], children, topElement }) => {
  const {
    axisLabelisVisible = false,
    legendIsVisible,
    axisLabel,
    xAxis,
    yAxis,
    height = 375,
    maxHeight = 100,
    chartType,
    multiple = false,
    legendVerticalAlign = 'bottom',
    legendIconType = 'line',
    gridIsVisible = true,
    legendIconSize = 10,
    showHarmonics = false,
    timeRangeSelector = false,
    showToolBox = true,
    color = '#6B7280',
    dataFactor = 3.2,
    hideXAxis = false,
    hideZoomReset = false,
    disableZoom = false,
    minimumTimeRangeKey = 0,
  } = options;
  const [state, setState] = useState(initial(data, '', '', xAxis, yAxis));
  const tempHarmonicSettings = { harmonicHandler: false };

  if (handledHarmonics) {
    handledHarmonics.forEach(harmonic => {
      tempHarmonicSettings[harmonic[0]._id] = false;
    });
  }
  const [harmonicSettings, setHarmonicsSettings] = useState(tempHarmonicSettings);
  useEffect(() => {
    setState(prev => {
      const xTicks = multiple
        ? generateXAxisValues([xAxis.minValue, xAxis.maxValue], '', '', xAxis.tickCount)
        : prev.xTicks ||
          generateXAxisValues([xAxis.minValue, xAxis.maxValue], '', '', xAxis.tickCount);
      return {
        data,
        yTicks: generateYAxisValues([yAxis.minValue, yAxis.maxValue], yAxis.tickCount),
        xTicks,
        left: xTicks[0],
        right: xTicks[xTicks.length - 1],
      };
    });
  }, [data, yAxis, xAxis, multiple]);

  const zoom = () => {
    if (disableZoom) {
      return;
    }
    let { refAreaLeft, refAreaRight } = state;
    if (refAreaLeft === refAreaRight || (refAreaRight === '' && refAreaRight === '')) {
      setState(prevState => ({
        ...prevState,
        refAreaLeft: '',
        refAreaRight: '',
      }));
      return;
    }

    if (refAreaLeft > refAreaRight) [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];

    setState(prevState => ({
      ...prevState,
      refAreaLeft: '',
      refAreaRight: '',
      left: refAreaLeft,
      right: refAreaRight,
      xTicks: generateXAxisValues(
        [xAxis.minValue, xAxis.maxValue],
        refAreaLeft,
        refAreaRight,
        xAxis.tickCount,
      ),
    }));
  };
  const { left, right, refAreaLeft, refAreaRight, yTicks, xTicks } = state;
  return (
    <div className="flex flex-col select-none ">
      {showToolBox && (
        <ChartControlMenu
          state={state}
          setState={setState}
          harmonicSettings={harmonicSettings}
          setHarmonicSettings={setHarmonicsSettings}
          showHarmonics={showHarmonics}
          xAxis={xAxis}
          timeRangeSelector={timeRangeSelector}
          handledHarmonics={handledHarmonics}
          hideZoomReset={hideZoomReset}
          minimumTimeRangeKey={minimumTimeRangeKey}
        />
      )}
      <ResponsiveContainer width="100%" height="100%" maxHeight={maxHeight} minHeight={height}>
        {data?.length >= 1 ? (
          <ComposedChart
            data={state.data}
            syncId={chartType}
            onMouseDown={e => e.activeLabel &&
              setState(prevState => ({ ...prevState, refAreaLeft: e.activeLabel }))}
            onMouseMove={e => {
              if (e.activeLabel && state.refAreaLeft) {
                setState(prevState => ({
                  ...prevState,
                  refAreaRight: e.activeLabel,
                }));
              }
            }}
            onMouseUp={zoom}
            onMouseLeave={zoom}
            margin={{ left: 0, top: 20, right: 20, bottom: 40 }}
            stackOffset="expand"
          >
            {gridIsVisible && <CartesianGrid vertical={false} />}
            <XAxis
              allowDataOverflow
              allowDuplicatedCategory={false}
              domain={disableZoom ? ['dataMin', 'dataMax'] : [left, right]}
              dataKey="x"
              type="number"
              tickFormatter={(tick, index) => tickFormatter(tick, index, xTicks, xAxis)}
              scale={disableZoom ? 'time' : undefined}
              interval={0}
              ticks={!disableZoom ? xTicks : undefined}
              axisLine={{ strokeOpacity: 0.1 }}
              className="text-xs"
              tickMargin={5}
              padding={{ left: 5, right: 5 }}
              stroke={color}
              hide={hideXAxis}
            >
              {axisLabelisVisible && !legendIsVisible && (
                <Label
                  value={axisLabel.x}
                  dy={40}
                  position="insideBottom"
                  style={{ textAnchor: 'middle' }}
                  stroke={color}
                  className="text-2xl font-extrabold"
                />
              )}
            </XAxis>
            <YAxis
              allowDataOverflow
              domain={
                multiple
                  ? ['auto', 'auto']
                  : [state.yTicks[0], state.yTicks[state.yTicks.length - 1]]
              }
              type="number"
              tickFormatter={e => formatFloat(e, yAxis.decimalsInFloat)}
              axisLine={false}
              interval={0}
              minTickGap={0}
              ticks={multiple ? undefined : yTicks}
              tickMargin={5}
              tickLine={false}
              className="text-xs font-semibold"
              stroke={color}
            >
              {axisLabelisVisible && (
                <Label
                  value={axisLabel.y}
                  position="insideLeft"
                  dx={5}
                  angle={-90}
                  style={{ textAnchor: 'middle' }}
                  stroke={color}
                  className="text-sm font-thin"
                />
              )}
            </YAxis>
            {legendIsVisible && (
              <Legend
                align="center"
                verticalAlign={legendVerticalAlign}
                wrapperStyle={{
                  top: legendVerticalAlign === 'top' ? 0 : null,
                  bottom: legendVerticalAlign === 'bottom' ? 15 : null,
                }}
                iconType={legendIconType}
                iconSize={legendIconSize}
              />
            )}
            <Tooltip
              dy={40}
              labelFormatter={(e, payload) => tooltipLabelFormatter(e, payload, xAxis)}
              formatter={(e, name, props) => tooltipFormatter(e, yAxis.decimalsInFloat, props)}
            />
            <ReferenceArea
              yAxis={1}
              x1={refAreaLeft}
              x2={refAreaRight}
              animationDuration={300}
              strokeOpacity={0.5}
              radius={5}
              stroke="gray"
              strokeWidth={1}
              fill="#C0DEFF"
            />
            {children}
            {showHarmonics && (
              <Customized
                component={(
                  <HarmonicIndicator
                    dataFactor={dataFactor}
                    selectedHarmonic={
                      harmonicSettings.harmonicHandler
                        ? undefined
                        : handledHarmonics.find(
                          harmonic => harmonic[0]._id ===
                              Object.keys(harmonicSettings).find(
                                key => harmonicSettings[key] === true,
                              ),
                        )
                    }
                    harmonicHandler={harmonicSettings.harmonicHandler}
                  />
                )}
              />
            )}
          </ComposedChart>
        ) : (
          <ChartSkeleton height={height < 200 ? 180 : 320} />
        )}
      </ResponsiveContainer>
      {topElement}
    </div>
  );
};

export default Rechart;
