import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { UpdateAnnotationForm } from './Forms';
import { getAnnotationsList } from '../../../../redux/actions';

function AnnotationUpdate() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { isFetched, data } = useSelector(state => state.annotationslist);
  const [editData, setEditData] = useState({});
  useEffect(() => {
    if (!isFetched) {
      dispatch(getAnnotationsList());
    }
  }, [dispatch, isFetched]);
  useEffect(() => {
    if (data.length > 0) {
      const activeAnnotation = data.find(a => a._id === id);
      setEditData(activeAnnotation);
    }
  }, [data, id]);
  return <div>{editData.mid && <UpdateAnnotationForm editData={editData} />}</div>;
}

export default AnnotationUpdate;
