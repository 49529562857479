import axios from 'axios';
import { call, put } from 'redux-saga/effects';

import { EXPRESS_API_URL } from '../../constants';
import { config, handleApiError } from '../utils';
import { GET_ANNOTATIONS_SUCCESS } from '../actions/type';

export function* getAnnotationsRequest(action) {
  try {
    const { mid } = action.payload;
    const res = yield call(axios.get, `${EXPRESS_API_URL}/annotations?mid=${mid}`, config);
    yield put({ type: GET_ANNOTATIONS_SUCCESS, payload: res.data });
  }
  catch (error) {
    handleApiError(error, 'getAnnotations');
  }
}
