import React from 'react';
import { Navigate } from 'react-router-dom';

import { useSelector } from 'react-redux';

import Navbar from '../shared/Navbar';
import DataTable from './components/DataTable';

export function Log() {
  const isAdmin = useSelector(state => state.user?.user?.user?.isAdmin);
  if (isAdmin === undefined) {
    return <Navbar />;
  }

  if (!isAdmin) {
    return <Navigate to="/" replace />;
  }
  return (
    <>
      <Navbar />
      <DataTable />
    </>
  );
}
