import * as actionTypes from '../actions/type';

export const rpmhistorygraph = (state = [], action) => {
  switch (action.type) {
    case actionTypes.GET_RPM_HISTORY_GRAPH_SUCCESS:
      return { ...state, [action.mid]: { isLoading: false, data: action.payload } };
    case actionTypes.GET_RPM_HISTORY_GRAPH:
      return { ...state, [action.mid]: { isLoading: true } };
    default:
      return state;
  }
};
