import { Line } from 'recharts';

import { createArrayForChart, yAxisLabelFormatter } from '../../utils';
import Rechart from '../Rechart/Rechart';

export default function TimeWaveChart({ data, activeDataType, activeType, xAxisMax }) {
  const labels = createArrayForChart(data.length, xAxisMax);
  let minY = null;
  let maxY = null;
  const preparedData = [];
  for (let i = 0; i <= labels.length; i++) {
    const yValue = data[i]?.y;
    if (yValue !== undefined) {
      preparedData.push({
        x: labels[i],
        y: yValue,
      });
      if (minY === null || yValue < minY) {
        minY = yValue;
      }
      if (maxY === null || yValue > maxY) {
        maxY = yValue;
      }
    }
  }

  const yAxisLabel = yAxisLabelFormatter(activeDataType, activeType);
  const options = {
    axisLabelisVisible: false,
    legendIsVisible: false,
    xAxisType: 'number',
    axisLabel: {
      x: 'Saniye',
      y: yAxisLabel,
    },
    xAxis: {
      type: 'number',
      tickCount: 3,
      maxValue: xAxisMax,
      minValue: 0,
      decimalsInFloat: 1,
    },
    yAxis: {
      tickCount: 10,
      minValue: minY * 2,
      maxValue: maxY * 2,
      decimalsInFloat: 2,
    },
    chartType: activeType,
    showHarmonics: true,
  };
  return (
    <Rechart data={preparedData} options={options}>
      <Line
        type="monotone"
        strokeWidth={2}
        stroke="#33b2df"
        dataKey="y"
        name={options.axisLabel.y}
        dot={false}
      />
    </Rechart>
  );
}
