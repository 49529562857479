import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { useTranslation } from 'react-i18next';

import PhotoSlider from './PhotoSlider';
import { CompanySkeleton } from '../../shared/Skeletons';

function Company() {
  const { machine } = useParams();
  const { factory, rpm, rpmReadingTimeUTC } = useSelector(state => state?.user.data.machine[0]);
  const isCompanyLoading = useSelector(state => state.user.isLoading);
  const { data } = useSelector(state => state?.sensors[machine]) || {};
  const isSensorsLoading = useSelector(state => state?.sensors[machine]?.isLoading) === undefined;
  const { t } = useTranslation();
  if (isSensorsLoading || isCompanyLoading) {
    return <CompanySkeleton />;
  }

  const validDates = data?.filter(item => item && item.date).map(item => moment(item.date));
  const latestDate =
    validDates?.length > 0 ? moment.max(validDates).format('YYYY-MM-DD HH:mm:ss') : null;

  return (
    <div className="col-span-4 ml-4 items-center bg-white border rounded-lg shadow-md md:flex-row md:max-w-xl dark:border-gray-700 dark:bg-gray-800 h-56">
      <div className="grid grid-cols-1">
        <div className="grid grid-cols-3">
          <div className="p-2 col-span-2 text-lg font-bold tracking-tight text-gray-900 dark:text-white" data-testid="company-factory">
            {factory}
          </div>
          <hr className="col-span-3" />
        </div>
        <div className="flex flex-row w-full">
          <div className="w-5/12">
            <div className="col-span-3 text-xs p-2">
              <p className="font-bold text-xs text-gray-400">{t('MACHINE_NAME')}</p>
              <p className="font-bold text-xs mb-2" data-testid="company-machine">{machine}</p>
              <p className="font-bold text-gray-400 text-xs">{t('MACHINE_NUMBER')}</p>
              <p className="font-bold  text-xs mb-2">{machine}</p>
              <p className="font-bold text-gray-400 text-xs">{t('LAST_DATA_TIME')}</p>
              <p className="font-bold  text-xs mb-2" data-testid="company-latest-date">{latestDate}</p>
              <p className="font-bold text-gray-400 text-xs">
                {machine === 'E1_1 Winder' ||
                machine === 'E1_2 Winder' ||
                machine === 'NC Winder' ||
                machine === 'CW4000 Winder'
                  ? 'Motor RPM'
                  : ' RPM'}
              </p>
              <p className="inline font-bold text-xs mb-2 mr-2">{rpm || '-'}</p>
              <span className="text-xs text-gray-400 font-bold text-gray">
                {rpmReadingTimeUTC
                  ? `(${moment.utc(rpmReadingTimeUTC).add(3, 'hours').format('DD.MM / HH:mm')})`
                  : ''}
              </span>
            </div>
          </div>
          <div className="w-7/12 flex justify-center">
            <PhotoSlider />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Company;
