import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { EditUserForm } from './Forms';
import { getUsersList } from '../../../../redux/actions';

function UserUpdate() {
  const { id } = useParams();
  const [editData, setEditData] = useState();
  const dispatch = useDispatch();
  const usersList = useSelector(state => state.userslist);

  useEffect(() => {
    if (!usersList.isFetched) {
      dispatch(getUsersList());
    }
  }, [dispatch, usersList.isFetched]);

  useEffect(() => {
    const selectedUser = usersList.data.find(user => user._id === id);
    setEditData(selectedUser);
  }, [id, usersList.data]);
  return <div>{editData && <EditUserForm editData={editData} />}</div>;
}

export default UserUpdate;
