import axios from 'axios';
import { call, put } from 'redux-saga/effects';

import { EXPRESS_API_URL } from '../../constants';
import { config, handleApiError } from '../utils';
import { GET_MACHINE_DATA_SUCCESS, GET_RISK_INDEXES } from '../actions/type';

export function* getMachinesRequest() {
  try {
    const res = yield call(axios.post, `${EXPRESS_API_URL}/machines/list`, {}, config);
    yield put({ type: GET_MACHINE_DATA_SUCCESS, payload: res.data });

    // eslint-disable-next-line no-restricted-syntax
    for (const machine of res.data.slice(0, 9)) {
      yield put({ type: GET_RISK_INDEXES, payload: { mid: machine.mid } });
    }
  }
  catch (error) {
    handleApiError(error, 'getMachines');
  }
}
