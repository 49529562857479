import { Tabs } from '@material-tailwind/react';

import { useSelector } from 'react-redux';

import ChartTabsBody from './ChartTabsBody';
import ChartTabsHeader from './ChartTabsHeader';

const ChartTabs = () => {
  const { activeSensor, activeType, activeDataType } = useSelector(state => state.activeData);
  const key = `${activeType}-${activeDataType}-${activeSensor}`;
  return (
    <Tabs key={key} value={`${activeType}_${activeSensor}_${activeDataType}`}>
      <ChartTabsHeader />
      <ChartTabsBody />
    </Tabs>
  );
};

export default ChartTabs;
