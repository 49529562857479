import * as actionTypes from '../actions/type';
import {
  getHighestValue,
  sortedIstacMachines,
  sortedSuborMachines,
} from '../../pages/Machines/utils';

export const machines = (state = [], action) => {
  const fakeMachines = [];
  if (action.type === actionTypes.GET_MACHINE_DATA_SUCCESS) {
    const toCompleteTen = 10 - action.payload.length;
    for (let i = 0; i < toCompleteTen; i++) {
      fakeMachines.push({ mid: 'Gelecek Makine', visibleName: 'Gelecek Makine' });
    }
  }
  switch (action.type) {
    case actionTypes.GET_MACHINE_DATA_SUCCESS:
      return {
        isLoading: true,
        machinesData: [...action.payload, ...fakeMachines],
        machinesOK: true,
      };
    case actionTypes.GET_MACHINE_DATA:
      return { isLoading: true, machinesOK: false };
    case actionTypes.GET_RISK_INDEXES_SUCCESS:
      if (state?.machinesOK) {
        const preparedMachines = state.machinesData.map(machine => {
          if (machine.mid !== 'Gelecek Makine') {
            if (action.mid === machine.mid) {
              const highestValue = getHighestValue(action.payload);
              return { ...machine, highestValue };
            }
          }
          return machine;
        });
        if (
          preparedMachines
            .filter(item => item.mid !== 'Gelecek Makine')
            .every(item => item.highestValue >= 0)
        ) {
          let sortedMachines = [];
          if (preparedMachines.some(item => item.mid === 'ERS 55')) {
            sortedMachines = preparedMachines.sort((a, b) => {
              const aIndex = sortedSuborMachines.indexOf(a.mid);
              const bIndex = sortedSuborMachines.indexOf(b.mid);

              return aIndex - bIndex;
            });
          }
          else if (preparedMachines.some(item => item.mid === 'UF-2A')) {
            sortedMachines = preparedMachines.sort((a, b) => {
              const aIndex = sortedIstacMachines.indexOf(a.mid);
              const bIndex = sortedIstacMachines.indexOf(b.mid);

              return aIndex - bIndex;
            });
          }
          else {
            sortedMachines = preparedMachines.sort((a, b) => b.highestValue - a.highestValue);
          }
          return { isLoading: false, machinesOK: true, machinesData: sortedMachines };
        }
        return { isLoading: false, machinesOK: true, machinesData: preparedMachines };
      }
      return state;
    default:
      return state;
  }
};
