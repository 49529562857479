import { Dot } from 'recharts';

export default function ColoredDot({ cx, cy, payload }) {
  let color = payload.isAnomaly ? 'red' : '#31ad25';
  if (payload.isExcluded) {
    color = 'gray';
  }

  return <Dot cx={cx} cy={cy} r={1} stroke={color} fill={color} />;
}
