import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  trTR,
  enUS,
} from '@mui/x-data-grid';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { LinearProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { getStateHistory } from '../../../redux/actions';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

function StateHistory() {
  const dispatch = useDispatch();
  const { machine } = useParams();
  let { stateHistory } = useSelector(state => state?.statehistory);
  const { isLoading } = useSelector(state => state?.statehistory);

  // Temporary
  if (machine === 'UF-1A' || machine === 'UF-2B_1') {
    stateHistory = stateHistory?.filter(item => {
      const itemDate = new Date(item.createdAtFormatted);
      const cutoffDate = new Date('2024-08-13');
      return itemDate >= cutoffDate;
    });
  }
  if (machine === 'UF-2B' || machine === 'UF-2C') {
    stateHistory = stateHistory?.filter(item => {
      const itemDate = new Date(item.createdAtFormatted);
      const cutoffDate = new Date('2024-08-13');
      return itemDate < cutoffDate;
    });
  }
  // Temporary

  const { t, i18n } = useTranslation();

  const localeText =
    {
      'en-US': enUS,
      tr: trTR,
    }[i18n.language] || trTR;

  useEffect(() => {
    dispatch(getStateHistory(machine));
  }, [dispatch, machine]);

  const columns = [
    { field: 'id', headerName: 'ID', hide: true },
    { field: 'date', headerName: t('DATE'), width: 150 },
    { field: 'score', headerName: t('RISK_SCORE') },
    { field: 'sensor', headerName: t('SENSOR'), width: 150 },
  ];

  const rows = stateHistory?.map((state, index) => ({
    id: index,
    date: state.createdAtFormatted,
    score: `% ${Math.floor(state.highestPercentage)}`,
    sensor: `Sensör ${state.sid} ${state.dataType}`,
  }));
  if (isLoading || !stateHistory) return <LinearProgress />;
  return (
    <div className="col-span-4 row-span-6 p-1 mt-5 ml-4 h-96">
      <p className="text-black-900 text-base p-2 border rounded-t-lg border-b-0 h-10 ">
        <b>{t('RISK_STATE_HISTORY')}</b>
      </p>

      <DataGrid
        rows={rows}
        columns={columns}
        density="compact"
        width="100%"
        components={{ Toolbar: CustomToolbar }}
        localeText={localeText.components.MuiDataGrid.defaultProps.localeText}
      />
    </div>
  );
}

export default StateHistory;
