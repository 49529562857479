import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'antd';

import { ReloadOutlined } from '@ant-design/icons';

import { useNavigate } from 'react-router-dom';

import SensorsListTable from './components/SensorsListTable';
import { getSensors } from '../../../../redux/actions';
import Header from '../../components/Header';
import RenderLayout from '../../components/RenderLayout';

function SensorsList() {
  const { isLoading, isFetched, data } = useSelector(state => state.sensorslist);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isFetched) {
      dispatch(getSensors());
    }
  }, [dispatch, isFetched]);
  return (
    <div>
      <Header
        title="Sensors"
        rightComponent={(
          <>
            <Button
              type="success"
              icon={<ReloadOutlined />}
              onClick={() => {
                dispatch(getSensors());
              }}
            />
            <Button
              type="primary"
              className="ml-auto mt-2"
              onClick={() => {
                navigate('/admin/sensors/new');
              }}
            >
              Add Sensor
            </Button>
          </>
        )}
      />
      <RenderLayout isFetched={isFetched} isLoading={isLoading}>
        <SensorsListTable data={data} />
      </RenderLayout>
    </div>
  );
}

export default SensorsList;
