import React from 'react';

import { NewMachineForm } from './Forms';

function MachineSave() {
  return (
    <div>
      <NewMachineForm />
    </div>
  );
}

export default MachineSave;
