import React from 'react';
import { Table } from 'antd';

import { DeletePointIdForm } from '../Forms';

const PointIdsListTable = ({ data }) => {
  const pointIdFilter = [...new Set(data.map(pointIdData => pointIdData.pointId))].map(
    pointId => ({ text: pointId, value: pointId }),
  );
  const machineFilter = [...new Set(data.map(pointIdData => pointIdData.mid))].map(mid => ({
    text: mid,
    value: mid,
  }));
  const sidFilter = [...new Set(data.map(pointIdData => pointIdData.sid))].map(sid => ({
    text: sid,
    value: sid,
  }));
  const dataTypeFilter = [...new Set(data.map(pointIdData => pointIdData.dataType))].map(
    dataType => ({ text: dataType, value: dataType }),
  );
  const typeFilter = [...new Set(data.map(pointIdData => pointIdData.type))].map(type => ({
    text: type,
    value: type,
  }));
  const columns = [
    {
      title: 'PointId',
      dataIndex: 'pointId',
      key: 'pointId',
      width: 150,
      fixed: 'left',
      filters: pointIdFilter,
      filterSearch: true,
      onFilter: (value, record) => record.pointId === value,
    },
    {
      title: 'Mid',
      dataIndex: 'mid',
      key: 'mid',
      width: 150,
      filters: machineFilter,
      onFilter: (value, record) => record.mid === value,
    },
    {
      title: 'Sid',
      width: 100,
      dataIndex: 'sid',
      key: 'sid',
      filters: sidFilter,
      onFilter: (value, record) => record.sid === value,
    },
    {
      title: 'Data Type',
      dataIndex: 'dataType',
      key: 'dataType',
      width: 150,
      filters: dataTypeFilter,
      onFilter: (value, record) => record.dataType === value,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 150,
      filters: typeFilter,
      onFilter: (value, record) => record.type === value,
    },
    {
      title: 'Action',
      key: 'operation',
      width: 100,
      render: item => <DeletePointIdForm data={item} />,
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={data.map((item, index) => ({ key: index, ...item }))}
      className="min-h-screen h-full"
      scroll={{ x: 1300 }}
    />
  );
};
export default PointIdsListTable;
