import axios from 'axios';
import { call, put } from 'redux-saga/effects';

import { EXPRESS_API_URL } from '../../constants';
import { config, handleApiError } from '../utils';
import { GET_SENSORS_DATA_SUCCESS, SET_ACTIVE_SENSOR } from '../actions/type';

export function* getSensorsDataRequest(action) {
  try {
    const { mid } = action.payload;
    const res = yield call(axios.post, `${EXPRESS_API_URL}/sensors`, { mid }, config);
    yield put({ type: GET_SENSORS_DATA_SUCCESS, payload: res.data, mid });
    yield put({ type: SET_ACTIVE_SENSOR, sensor: res.data.at(0).sid });
  }
  catch (error) {
    handleApiError(error, 'getSensorsData');
  }
}
