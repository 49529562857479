import * as actionTypes from '../actions/type';

const INITIAL_STATE = {
  activeSensor: '1',
  activeType: 'trend',
  activeDataType: 'RMS',
};

export const activeData = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SET_ACTIVE_SENSOR:
      return { ...state, activeSensor: action.sensor };
    case actionTypes.SET_ACTIVE_TYPE:
      return { ...state, activeType: action.sensorType };
    case actionTypes.SET_ACTIVE_DATATYPE:
      return { ...state, activeDataType: action.dataType };
    default:
      return state;
  }
};
