import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Tab } from '@material-tailwind/react';

import { t } from 'i18next';

import Company from './components/Company';
import RiskOverTime from './components/RiskOverTimes';
import StateHistory from './components/StateHistory';
import Navbar from '../shared/Navbar';
import { DATA_TYPES } from './constants';
import {
  getAnnotations,
  getChartData,
  getMultivariateAnomaliesData,
  getChartTypes,
  getLiveStatus,
  getRiskIndexes,
  setActiveDataType,
  setActiveSensor,
  setActiveType,
  getSensorsData,
} from '../../redux/actions';
import { dataTypes } from './constantValues';
import TypeTabs from './components/TypeTabs';
import { MultipleChart } from './components/GetChart';
import RpmHistory from './components/RpmHistory';
import PeriodicRecords from './components/PeriodicRecords';
import { sortedIstacMachines, sortedSuborMachines } from './utils';
import { ChartSkeleton } from '../shared/Skeletons';

const MultipleChartMemoized = React.memo(MultipleChart);

function Machine() {
  const { machine } = useParams();
  const dispatch = useDispatch();
  const { activeSensor, activeType, activeDataType } = useSelector(state => state.activeData);
  const activeOption = useSelector(state => state?.timeOptions?.find(option => option.active))?.ReadingTimeUTC;
  const { data, isLoading } = useSelector(state => state.sensors)[machine] || {};
  const { data: multivariateAnomaliesData, isLoading: multivariateAnomaliesIsLoading } = useSelector(state => state.multivariateAnomalies);
  const activeSensors = useMemo(() => {
    if (isLoading === false) {
      return data.filter(item => item.dataType === activeDataType).map(item => item.sid);
    }
    return [];
  }, [isLoading, data, activeDataType]);
  useEffect(() => {
    if (
      isLoading === false &&
      (activeType === 'trend' || (activeType === 'heat' && activeDataType === 'TEMP'))
    ) {
      activeSensors.forEach(sensor => {
        dispatch(getChartData(machine, sensor, activeType, activeDataType));
      });
    }
  }, [dispatch, machine, activeType, activeDataType, isLoading, activeSensors]);

  useEffect(() => {
    if (!multivariateAnomaliesData?.[machine]?.[activeDataType]) {
      dispatch(getMultivariateAnomaliesData(machine, activeType, activeDataType));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, machine, activeType, activeDataType]);

  useEffect(() => {
    if (isLoading === false && activeType !== 'trend') {
      dispatch(getChartData(machine, activeSensor, activeType, activeDataType, activeOption));
    }
  }, [dispatch, machine, activeSensor, activeType, activeDataType, activeOption, isLoading]);

  useEffect(() => {
    dispatch(getSensorsData(machine));
    dispatch(getLiveStatus(machine));
    dispatch(getRiskIndexes(machine));
    dispatch(getChartTypes(machine));
    dispatch(getAnnotations(machine));
  }, [dispatch, machine]);

  const changeType = dataType => {
    dispatch(setActiveType(dataType === 'TEMP' ? 'heat' : 'trend'));
    dispatch(setActiveDataType(dataType));
    if (isLoading === false) {
      dispatch(setActiveSensor(activeSensors[0]));
    }
  };
  return (
    <>
      <Navbar />
      <div className="mx-1 grid grid-cols-12 grid-rows-12">
        <div className="col-span-8">
          <TypeTabs DATA_TYPES={DATA_TYPES} dataTypes={dataTypes} changeType={changeType}>
            <div className="rounded-md row-span-6 p-5 mt-5 h-full">
              <Tab className="border-b mb-3 text-left place-items-start">
                {t('SENSOR_COMPARISON')}
              </Tab>
              {
                (multivariateAnomaliesIsLoading === false && multivariateAnomaliesData?.[machine]?.[activeDataType]?.[activeType] !== undefined) ? (
                  <MultipleChartMemoized
                    series={multivariateAnomaliesData?.[machine]?.[activeDataType]?.[activeType]}
                    activeDataType={activeDataType}
                  />
                )
                  : <ChartSkeleton />
              }
            </div>
          </TypeTabs>
        </div>
        <div className="col-span-4 mt-3">
          <Company />
          <RiskOverTime />
          {sortedSuborMachines.includes(machine) && <RpmHistory />}
          {!sortedIstacMachines.includes(machine) && <PeriodicRecords />}
          <StateHistory />
        </div>
      </div>
    </>
  );
}

export default Machine;
