import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { ChartSkeleton } from '../../shared/Skeletons';
import PeriodicRecordsChart from './Charts/PeriodicRecordsChart';
import { getPeriodicRecordsGraph } from '../../../redux/actions';

function PeriodicRecords() {
  const { machine } = useParams();
  const dispatch = useDispatch();
  const { activeSensor, activeType, activeDataType } = useSelector(state => state.activeData);
  const { t } = useTranslation();
  const { isLoading, data, isFetched } = useSelector(state => state?.periodicrecordsgraph);
  useEffect(() => {
    dispatch(getPeriodicRecordsGraph(machine, activeSensor, activeType, activeDataType));
  }, [activeDataType, activeSensor, activeType, dispatch, machine]);
  if (isLoading || !data) {
    return <ChartSkeleton />;
  }
  return (
    data.length > 0 && (
    <div className="col-span-4 ml-4 mt-5 flex bg-white border rounded-lg flex-col md:max-w-xl dark:border-gray-700 dark:bg-gray-800 h-80">
      <div className="grid grid-cols-1">
        <div className="grid grid-cols-3">
          <div className="px-2 pt-2 col-span-12 text-lg font-bold tracking-tight text-gray-900 dark:text-white">
            {t('MONTHLY_TREND_AVERAGES')}
          </div>
        </div>
      </div>
      <div id="area-datetime" className="py-0 px-1  w-full h-full">
        {isFetched && !isLoading && (
          <PeriodicRecordsChart
            data={data.periodicTrend.series.values.map(item => ({
              ...item,
              x: new Date(item.x).getTime(),
            }))}
          />
        )}
      </div>
    </div>
    )
  );
}

export default PeriodicRecords;
