import axios from 'axios';
import { call, put } from 'redux-saga/effects';

import { EXPRESS_API_URL } from '../../constants';
import { config, handleApiError } from '../utils';
import { GET_RISK_INDEXES_GRAPH_SUCCESS, GET_RISK_INDEXES_SUCCESS } from '../actions/type';

export function* getRiskIndexesRequest(action) {
  try {
    const { mid } = action.payload;
    if (mid === undefined) return;
    const res = yield call(
      axios.post,
      `${EXPRESS_API_URL}/riskMeasurements/indexes`,
      { mid },
      config,
    );
    yield put({ type: GET_RISK_INDEXES_SUCCESS, payload: res.data, mid });
  }
  catch (error) {
    handleApiError(error, 'getRiskIndexes');
  }
}

export function* getRiskIndexesGraphRequest(action) {
  try {
    const { mid } = action.payload;
    if (mid === undefined) return;
    const res = yield call(
      axios.post,
      `${EXPRESS_API_URL}/riskMeasurements/graph`,
      { mid },
      config,
    );
    const { data } = res;
    yield put({ type: GET_RISK_INDEXES_GRAPH_SUCCESS, payload: { data, mid } });
  }
  catch (error) {
    handleApiError(error, 'getRiskIndexesGraph');
  }
}
