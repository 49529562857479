import React from 'react';

import { useParams } from 'react-router-dom';

import { ChangePasswordForm } from './Forms';

function ChangePassword() {
  const { id } = useParams();
  return (
    <div>
      <ChangePasswordForm id={id} />
    </div>
  );
}

export default ChangePassword;
