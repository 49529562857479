import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';

import { useNavigate } from 'react-router-dom';

import Header from '../../components/Header';

import { getPointIdsList } from '../../../../redux/actions';
import PointIdsListTable from './components/PointIdsListTable';
import RenderLayout from '../../components/RenderLayout';

function PointIdsList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, isFetched, data } = useSelector(state => state.pointidslist);
  useEffect(() => {
    if (!isFetched) {
      dispatch(getPointIdsList());
    }
  }, [dispatch, isFetched]);
  return (
    <div>
      <Header
        title="PointIds"
        rightComponent={(
          <>
            <Button
              type="success"
              icon={<ReloadOutlined />}
              onClick={() => {
                dispatch(getPointIdsList());
              }}
            />
            <Button
              type="primary"
              className="ml-auto mt-2"
              onClick={() => {
                navigate('/admin/pointIds/new');
              }}
            >
              Add PointIds
            </Button>
          </>
        )}
      />
      <RenderLayout isFetched={isFetched} isLoading={isLoading}>
        <PointIdsListTable data={data} />
      </RenderLayout>
    </div>
  );
}

export default PointIdsList;
