import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { v4 as uuidv4 } from 'uuid';

import { useTranslation } from 'react-i18next';

import Navbar from '../shared/Navbar';
import { getMachines } from '../../redux/actions';
import { setClassForStatus, setNameForStatus } from './components/config';
import RiskOverTime from './components/RiskOverTimes';
import RiskIndexes from './components/RiskIndexes';
import FullPageLoading from '../shared/FullPageLoading';

export function Home() {
  const dispatch = useDispatch();
  const { machinesData, isLoading } = useSelector(state => state?.machines);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getMachines());
  }, [dispatch]);

  if (isLoading || machinesData?.length !== 10) {
    return <FullPageLoading />;
  }

  const handleClick = (e, mid) => {
    if (mid !== 'Gelecek Makine') {
      window.location.href = `/machines/${mid}`;
    }
  };

  return (
    <div>
      <Navbar />
      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5
      bg-gradient-to-bl from-[#002336] via-[#00354f] to-[#012235] min-h-screen h-full content-baseline"
      >
        {machinesData?.map((machineData, index) => {
          const {
            mid,
            isAnomalyExist,
            latestReadingTimeUTC,
            visibleName,
            highestValue: originalHighestValue,
            activeSensors,
          } = machineData;
          const highestValue = originalHighestValue === 0 ? 5 : originalHighestValue;
          const classNameForCard = setClassForStatus(isAnomalyExist);
          const opacity = mid === 'Gelecek Makine' ? 'opacity-50 ' : '';
          return (
            <div
              role="button"
              tabIndex={index}
              key={uuidv4()}
              onClick={e => handleClick(e, mid)}
              className={`${opacity} p-6 pt-8 flex flex-col justify-between
                   h-[500px] transition duration-300 cursor-pointer border-b border-r`}
            >
              <div className="w-full h-auto hover:scale-[1.02] transition duration-300">
                <div className=" font-calibri text-xl text-left text-white ">
                  <div className="flex flex-row">
                    <div className="mx-1 font-base"> {t(visibleName.split('|')[0])}</div>
                    <div className="mx-1 font-extrabold">{visibleName.split('|')[1]}</div>
                  </div>
                </div>
                {mid === 'Gelecek Makine' ? (
                  ''
                ) : (
                  <>
                    <div className="flex flex-row flex-wrap  mt-10 mx-1">
                      <div
                        role="button"
                        tabIndex={index}
                        className="flex flex-col  w-1/2 gap-4"
                        onClick={e => handleClick(e, mid)}
                      >
                        <div className="flex ">
                          <div className="text-left flex flex-col">
                            <div className="text-xl font-calibri text-white">
                              {t('CURRENT_STATUS')}
                            </div>
                            <div className="text-left flex flex-row align-items-center mt-2">
                              <div
                                className={`${classNameForCard}-500 bg-white/50 border-2 w-4 h-4 border-r-white rounded-full animate-spin-slow`}
                              >
                                <div
                                  className={`${classNameForCard}-400 loop w-3 h-3  rounded-full animate-pulse`}
                                />
                              </div>
                              <p className="font-calibri text-base mx-3 text-white">
                                {t(setNameForStatus(isAnomalyExist))}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="flex ">
                          <div className="text-white text-lg font-calibri  items-center gap-2">
                            <div className="text-left flex flex-col">
                              <div>{t('CURRENT_STATUS_TIME')}</div>
                              <div>
                                {mid !== 'Gelecek Makine' ? latestReadingTimeUTC : '-------'}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex ">
                          <div className="text-white text-lg font-calibri  items-center gap-2">
                            <div className="text-left flex flex-col">
                              {t('ACTIVE_SENSORS')}: {activeSensors}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col w-1/2  items-center ">
                        <div className="text-white text-xl font-calibri px-3 items-center">
                          {t('RISK_SCORE')}
                        </div>
                        <RiskIndexes machine={mid} highestValue={highestValue} />
                      </div>
                    </div>
                    <div className="flex mt-0 flex-col justify-start items-center   rounded-xl p-0">
                      {mid === 'Gelecek Makine' ? '' : <RiskOverTime machine={mid} />}
                    </div>
                  </>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
