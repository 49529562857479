import { Button, Select, Form, Input, message, Popconfirm } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { useEffect, useState } from 'react';

import { getCompanies } from '../../../../redux/actions';
import {
  authDeleteRequest,
  authPostRequest,
  authPutRequest,
} from '../../../../services/api-service';

export function NewUserForm() {
  const dispatch = useDispatch();
  const companiesFetched = useSelector(state => state.companies.isFetched);
  const companiesLoading = useSelector(state => state.companies.isLoading);
  const companiesData = useSelector(state => state.companies.data);
  const [messageApi, contextHolder] = message.useMessage();
  const onFinish = async data => {
    const requestData = data;
    const response = await authPostRequest('auth/register', requestData);
    if (response.statusCode === 200) {
      messageApi.open({
        type: 'success',
        content: 'User has been added.',
      });
    }
    else {
      messageApi.open({
        type: 'error',
        content: response.message || response.statusCode,
      });
    }
  };
  useEffect(() => {
    if (!companiesFetched) {
      dispatch(getCompanies());
    }
  }, [companiesFetched, dispatch]);
  const statusOptions = [true, false].map(item => ({ label: String(item), value: item }));
  const roleOptions = ['user', 'admin'].map(item => ({ label: item, value: item }));
  return (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 600 }}
      initialValues={{ status: true, role: 'admin' }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item label="Name" name="name" required>
        <Input placeholder="Jane Doe..." />
      </Form.Item>
      <Form.Item label="Email" name="email" required>
        <Input placeholder="example@pdmechanics.com" />
      </Form.Item>
      <Form.Item label="Password" name="password" required>
        <Input.Password placeholder="**********" />
      </Form.Item>
      <Form.Item label="Company" name="company" required>
        <Select
          style={{ width: 220 }}
          options={companiesData?.map(companyData => ({
            value: companyData._id,
            label: companyData.company,
          }))}
          loading={companiesLoading}
        />
      </Form.Item>
      <Form.Item label="Role" name="role" required>
        <Select style={{ width: 220 }} options={roleOptions} />
      </Form.Item>
      <Form.Item label="Status" name="status" required>
        <Select style={{ width: 220 }} options={statusOptions} />
      </Form.Item>
      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
      {contextHolder}
    </Form>
  );
}

export function EditUserForm({ editData }) {
  const dispatch = useDispatch();
  const companiesFetched = useSelector(state => state.companies.isFetched);
  const companiesLoading = useSelector(state => state.companies.isLoading);
  const companiesData = useSelector(state => state.companies.data);
  const [messageApi, contextHolder] = message.useMessage();
  const onFinish = async data => {
    const requestData = data;
    const response = await authPutRequest('auth/users/update', editData._id, requestData);
    if (response.statusCode === 200) {
      messageApi.open({
        type: 'success',
        content: 'User has been updated.',
      });
    }
    else {
      messageApi.open({
        type: 'error',
        content: response.message || response.statusCode,
      });
    }
  };
  useEffect(() => {
    if (!companiesFetched) {
      dispatch(getCompanies());
    }
  }, [companiesFetched, dispatch]);
  const statusOptions = [true, false].map(item => ({ label: String(item), value: item }));
  const roleOptions = ['user', 'admin'].map(item => ({ label: item, value: item }));
  return (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 600 }}
      initialValues={editData}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item label="Name" name="name" required>
        <Input placeholder="Jane Doe..." />
      </Form.Item>
      <Form.Item label="Email" name="email" required>
        <Input placeholder="example@pdmechanics.com" />
      </Form.Item>
      <Form.Item label="Company" name="company" required>
        <Select
          style={{ width: 220 }}
          options={companiesData?.map(companyData => ({
            value: companyData._id,
            label: companyData.company,
          }))}
          loading={companiesLoading}
        />
      </Form.Item>
      <Form.Item label="Role" name="role" required>
        <Select style={{ width: 220 }} options={roleOptions} />
      </Form.Item>
      <Form.Item label="Status" name="status" required>
        <Select style={{ width: 220 }} options={statusOptions} />
      </Form.Item>
      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
      {contextHolder}
    </Form>
  );
}

export function DeleteUserForm({ data }) {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const showPopconfirm = () => {
    setOpen(true);
  };
  const handleOk = async () => {
    setConfirmLoading(true);
    const response = await authDeleteRequest('auth/users/delete', data._id);
    if (response.statusCode === 200) {
      messageApi.open({
        type: 'success',
        content: 'User has been deleted.',
      });
      setOpen(false);
      setConfirmLoading(false);
    }
    else {
      messageApi.open({
        type: 'error',
        content: response.message || response.statusCode,
      });
    }
  };
  const handleCancel = () => {
    setOpen(false);
  };
  return (
    <Popconfirm
      title="Delete the user"
      description="Are you sure to delete this user?"
      open={open}
      onConfirm={handleOk}
      okButtonProps={{ loading: confirmLoading }}
      onCancel={handleCancel}
    >
      <Button danger onClick={showPopconfirm}>
        Delete
      </Button>
      {contextHolder}
    </Popconfirm>
  );
}

export function ChangePasswordForm({ id }) {
  const [messageApi, contextHolder] = message.useMessage();
  const onFinish = async data => {
    const requestData = data;
    const response = await authPutRequest('auth/users/changePassword', id, requestData);
    if (response.statusCode === 200) {
      messageApi.open({
        type: 'success',
        content: 'Password has been changed.',
      });
    }
    else {
      messageApi.open({
        type: 'error',
        content: response.message || response.statusCode,
      });
    }
  };
  return (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 600 }}
      initialValues={{ status: true, role: 'admin' }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item name="password" label="Password" required>
        <Input.Password placeholder="Input the password." />
      </Form.Item>
      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
      {contextHolder}
    </Form>
  );
}
