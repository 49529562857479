import { call, put } from 'redux-saga/effects';

import axios from 'axios';

import logService from '../../services/log.service';
import { GET_LOGS_SUCCESS, SAVE_LOG_SUCCESS } from '../actions/type';
import { EXPRESS_API_URL } from '../../constants';
import { config, handleApiError } from '../utils';

export function* saveLogRequest(action) {
  const { log, userInfo, machine } = action.payload;
  yield logService.saveLog(log, userInfo, machine);
  yield put({ type: SAVE_LOG_SUCCESS });
}

export function* getLogsRequest(action) {
  try {
    const { filters } = action;
    const res = yield call(axios.get, `${EXPRESS_API_URL}/logs/list`, {
      params: { ...filters },
      ...config,
    });
    const { data, totalCount } = res.data;
    yield put({ type: GET_LOGS_SUCCESS, payload: data, totalCount, filters });
  }
  catch (error) {
    handleApiError(error, 'getLogs');
  }
}
