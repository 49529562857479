import { createBrowserRouter } from 'react-router-dom';

import { Suspense } from 'react';

import Protected from './components/Protected';
import { Home } from './pages/Home';
import Machine from './pages/Machines';
import { Log } from './pages/Logs';
import { Admin } from './pages/Admin';
import { NotFound } from './pages/NotFound';
import { Login } from './pages/Login';
import FullPageLoading from './pages/shared/FullPageLoading';
import MachinesList from './pages/Admin/menu/Machines/MachinesList';
import MachineDetail from './pages/Admin/menu/Machines/MachineDetail';
import MachineUpdate from './pages/Admin/menu/Machines/MachineUpdate';
import MachineSave from './pages/Admin/menu/Machines/MachineSave';
import CompaniesList from './pages/Admin/menu/Companies/CompaniesList';
import CompanyDetail from './pages/Admin/menu/Companies/CompanyDetail';
import CompanyUpdate from './pages/Admin/menu/Companies/CompanyUpdate';
import CompanySave from './pages/Admin/menu/Companies/CompanySave';
import SensorsList from './pages/Admin/menu/Sensors/SensorsList';
import SensorDetail from './pages/Admin/menu/Sensors/SensorDetail';
import SensorSave from './pages/Admin/menu/Sensors/SensorSave';
import PointIdsList from './pages/Admin/menu/PointIds/PointIdsList';
import PointIdSave from './pages/Admin/menu/PointIds/PointIdSave';
import UsersList from './pages/Admin/menu/Users/UsersList';
import UserUpdate from './pages/Admin/menu/Users/UserUpdate';
import UserSave from './pages/Admin/menu/Users/UserSave';
import ChangePassword from './pages/Admin/menu/Users/ChangePassword';
import LogsList from './pages/Admin/menu/Logs/LogsList';
import AnnotationsList from './pages/Admin/menu/Annotations/AnnotationsList';
import AnnotationUpdate from './pages/Admin/menu/Annotations/AnnotationUpdate';
import AnnotationSave from './pages/Admin/menu/Annotations/AnnotationSave';

const routerConfig = [
  {
    path: '/',
    element: (
      <Protected>
        <Home />
      </Protected>
    ),
  },
  {
    path: '/login',
    element: (
      <Suspense fallback={<FullPageLoading />}>
        <Login />
      </Suspense>
    ),
  },
  {
    path: '/machines/:machine',
    element: (
      <Protected>
        <Machine />
      </Protected>
    ),
  },
  {
    path: '/logs',
    element: (
      <Protected>
        <Log />
      </Protected>
    ),
  },
  {
    path: '/admin',
    element: (
      <Protected>
        <Admin />
      </Protected>
    ),
    children: [
      {
        path: 'machines/list',
        element: <MachinesList />,
      },
      {
        path: 'machines/:id',
        element: <MachineDetail />,
      },
      {
        path: 'machines/update/:id',
        element: <MachineUpdate />,
      },
      {
        path: 'machines/new',
        element: <MachineSave />,
      },
      {
        path: 'companies/list',
        element: <CompaniesList />,
      },
      {
        path: 'companies/:id',
        element: <CompanyDetail />,
      },
      {
        path: 'companies/update/:id',
        element: <CompanyUpdate />,
      },
      {
        path: 'companies/new',
        element: <CompanySave />,
      },
      {
        path: 'sensors/list',
        element: <SensorsList />,
      },
      {
        path: 'sensors/:id',
        element: <SensorDetail />,
      },
      {
        path: 'sensors/new',
        element: <SensorSave />,
      },
      {
        path: 'pointIds/list',
        element: <PointIdsList />,
      },
      {
        path: 'pointIds/new',
        element: <PointIdSave />,
      },
      {
        path: 'users/list',
        element: <UsersList />,
      },
      {
        path: 'users/update/:id',
        element: <UserUpdate />,
      },
      {
        path: 'users/new',
        element: <UserSave />,
      },
      {
        path: 'users/changePassword/:id',
        element: <ChangePassword />,
      },
      {
        path: 'logs/list',
        element: <LogsList />,
      },
      {
        path: 'annotations/list',
        element: <AnnotationsList />,
      },
      {
        path: 'annotations/update/:id',
        element: <AnnotationUpdate />,
      },
      {
        path: 'annotations/new',
        element: <AnnotationSave />,
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
];

const router = createBrowserRouter(routerConfig);

export default router;
