import * as actionTypes from '../actions/type';

const INITIAL_STATE = {};
export const sensors = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_SENSORS_DATA:
      return { ...state, [action.mid]: { data: {}, isLoading: true, isFetched: true } };
    case actionTypes.GET_SENSORS_DATA_SUCCESS:
      return { ...state, [action.mid]: { data: action.payload, isLoading: false, isFetched: true } };
    default:
      return state;
  }
};
