import React from 'react';

function Header({ title, leftComponent, rightComponent }) {
  return (
    <div className="w-full h-8 flex justify-between border-b mb-4">
      <h3 className="text-2xl font-bold">{title}</h3>
      <div className="flex justify-between">
        <div>{leftComponent}</div>
        <div>{rightComponent}</div>
      </div>
    </div>
  );
}

export default Header;
