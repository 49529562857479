import * as actionTypes from '../actions/type';

const initialState = {
  isFetched: false,
  isLoading: false,
  data: [],
  totalCount: 0,
  filters: {
    skip: 0,
    limit: 0,
  },
};
export const logs = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_LOGS:
      return { ...state, isFetched: true, isLoading: true };
    case actionTypes.GET_LOGS_SUCCESS:
      return {
        isFetched: true,
        isLoading: false,
        data: action.payload,
        totalCount: action.totalCount,
        filters: action.filters,
      };
    default:
      return state;
  }
};
