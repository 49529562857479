import { Button } from 'antd';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReloadOutlined } from '@ant-design/icons';

import { useNavigate } from 'react-router-dom';

import Header from '../../components/Header';

import { getAnnotationsList } from '../../../../redux/actions/index';
import RenderLayout from '../../components/RenderLayout';
import AnnotationsListTable from './components/AnnotationsListTable';

function AnnotationsList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, isFetched, data } = useSelector(state => state.annotationslist);
  useEffect(() => {
    if (!isFetched) {
      dispatch(getAnnotationsList());
    }
  }, [dispatch, isFetched]);
  return (
    <div className="flex flex-col">
      <Header
        title="Annotations"
        rightComponent={(
          <>
            <Button
              type="success"
              icon={<ReloadOutlined />}
              onClick={() => {
                dispatch(getAnnotationsList());
              }}
            />
            <Button
              type="primary"
              className="ml-auto mt-2"
              onClick={() => {
                navigate('/admin/annotations/new');
              }}
            >
              Add Annotation
            </Button>
          </>
        )}
      />
      <RenderLayout isFetched={isFetched} isLoading={isLoading}>
        <AnnotationsListTable data={data} />
      </RenderLayout>
    </div>
  );
}

export default AnnotationsList;
