import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Button, Divider, ListItemIcon, Menu, MenuItem } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Logout from '@mui/icons-material/Logout';
import { useTranslation } from 'react-i18next';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LanguageIcon from '@mui/icons-material/Language';

import { getUser } from '../../redux/actions';
import ChangeLanguage from './ChangeLanguage';

function Navbar() {
  const dispatch = useDispatch();
  const { machine } = useParams();
  const userData = useSelector(state => state.user.data).user;
  const { name, company } = userData;
  const isAdmin = userData.role === 'admin';
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getUser(machine));
  }, [dispatch, machine]);

  const handleLogout = () => {
    localStorage.removeItem('user');
    window.location.href = '/login';
    setAnchorEl(null);
  };

  const handleMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  return (
    <nav className="bg-[#013450] px-0 py-0 w-full z-20 top-0 left-0">
      <div className="flex flex-wrap items-center justify-between">
        <a href="/" className="flex items-center">
          <img className="h-16" src={company.image?.path} alt="Company Logo" />
        </a>
        <div className="flex md:order-2">
          <Button type="button" onClick={handleMenuOpen}>
            <span className="self-center text-md whitespace-nowrap text-white dark:text-white capitalize">
              {name} <br /> {company.company}
              {isMenuOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </span>
          </Button>
          <Menu
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            anchorEl={anchorEl}
            open={isMenuOpen}
            onClose={handleMenuClose}
          >
            <MenuItem>
              <ListItemIcon>
                <LanguageIcon />
              </ListItemIcon>
              <ChangeLanguage showLabel={false} />
            </MenuItem>
            {isAdmin && (
              <MenuItem>
                <ListItemIcon>
                  <AdminPanelSettingsIcon />
                </ListItemIcon>
                <Link to="/admin/companies/list">Admin</Link>
              </MenuItem>
            )}
            <Divider />
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout />
              </ListItemIcon>
              <Link to="/login">
                {t('LOGOUT')}
              </Link>
            </MenuItem>
          </Menu>
          <div className="h-16 w-16 bg-primary/10 rounded-sm flex items-center justify-center bg-cover bg-center" />
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
