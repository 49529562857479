import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getCompanies } from '../../../../redux/actions/index';
import { EditCompanyForm } from './Forms';

function CompanyUpdate() {
  const { id } = useParams();
  const [editData, setEditData] = useState();
  const dispatch = useDispatch();
  const companies = useSelector(state => state.companies);

  useEffect(() => {
    if (!companies.isFetched) {
      dispatch(getCompanies());
    }
  }, [dispatch, companies.isFetched]);

  useEffect(() => {
    const selectedCompany = companies.data.find(company => company._id === id);
    setEditData(selectedCompany);
  }, [companies.data, id]);
  return <div>{editData && <EditCompanyForm editData={editData} />}</div>;
}

export default CompanyUpdate;
