import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { useTranslation } from 'react-i18next';

import {
  // saveLog,
  setActiveSensor,
  setActiveType,
} from '../../../redux/actions';
import { dataTypes } from '../constantValues';
import { SensorsSkeleton } from '../../shared/Skeletons';

function Sensors({ dataType }) {
  const dispatch = useDispatch();
  const { machine } = useParams();
  const { data, isLoading } = useSelector(state => state.sensors)?.[machine] || {};
  const liveStatus = useSelector(state => state.liveStatus)?.[machine];
  // const userInfo = useSelector(state => state.user);
  const riskIndexes = useSelector(state => state.riskindexes);
  const { activeSensor, activeDataType } = useSelector(state => state.activeData);
  const { t } = useTranslation();
  const changeSensor = e => {
    const sensorName = e.split('_')[1];
    // const log = `${sensorName} tıklandı`;
    dispatch(setActiveSensor(sensorName));
    dispatch(setActiveType(dataType === 'TEMP' ? 'heat' : 'trend'));
    // dispatch(saveLog(log, userInfo, machine));
  };

  if (isLoading || !data) {
    return <SensorsSkeleton />;
  }

  const sensorsData = data?.filter(sensor => sensor.dataType === dataType) || [];

  return (
    <ul className="flex gap-6 w-[93%] mx-auto">
      {sensorsData.map(sensor => {
        const { sid, name, measurement, date } = sensor;
        const isAnomalyExist =
          liveStatus !== undefined && dataType !== 'TEMP' ? liveStatus[dataType]?.[sid] : false;
        const isWorking = moment(date).isAfter(moment().clone().subtract(24, 'hours'));
        let sensorClass;
        if (isWorking) {
          sensorClass = isAnomalyExist ? 'bg-orange-500' : 'bg-green-400/70';
        }
        else {
          sensorClass = 'bg-gray-500';
        }
        const id = `${sensor.dataType}_${sid}`;
        const riskIndex = riskIndexes?.[machine]?.data?.[dataType]?.[sid]?.toFixed(0);
        const isActive = `${activeDataType}_${activeSensor}` === id;
        const activeClass = isActive
          ? 'bg-gray-50 text-black active scale-[1.05]'
          : 'text-gray-500';

        return (
          <li
            className={`${sensorsData.length >= 5 ? 'w-full' : 'w-1/6'}`}
            key={`${id}liItem`}
            onClick={() => changeSensor(id)}
          >
            <input
              type="checkbox"
              id={`${id}input`}
              value={name}
              className="hidden peer"
              required
            />
            <label
              htmlFor="react-option"
              id={id}
              className={`${activeClass} transition-all inline-flex justify-between
                  items-center w-full rounded-lg border-gray-200 cursor-pointer peer-checked:border-blue-600
                   hover:text-black hover:transition-all peer-checked:text-gray-600 hover:bg-gray-50`}
            >
              <div className="w-full grid grid-cols-12">
                <div className="col-span-1 rounded-lg rounded-r-none">
                  <div className={`${sensorClass} w-3/5 h-full rounded-lg rounded-r-none`} />
                </div>
                <div className="col-span-11 p-2">
                  <div className="flex">
                    <div className="w-full text-base font-semibold mb-4">{name}</div>
                  </div>
                  <div className="grid grid-cols-1">
                    <div>
                      <p className="font-bold text-gray-400 text-xs">{t('MEASUREMENT')}:</p>
                      <p className="font-bold text-xs mb-2">
                        {measurement?.toFixed(dataTypes[dataType]?.toFixed)}{' '}
                        {dataTypes[dataType]?.unit}
                      </p>
                    </div>
                    <div className="grid grid-cols-1">
                      <div className="col-span-1">
                        <p className="font-bold text-gray-400 text-xs">{t('RISK_INDEX')}:</p>
                      </div>
                      <div className="col-span-1">
                        <p className="font-bold text-sm mb-2">
                          {dataType !== 'TEMP' && riskIndex !== undefined ? `%${riskIndex}` : '-'}
                        </p>
                      </div>
                    </div>
                  </div>
                  <p className="font-bold text-gray-400 text-xs">{t('TIME')}:</p>
                  <p className="font-bold text-xs">{moment(date).format('DD-MM HH:mm')}</p>
                </div>
              </div>
            </label>
          </li>
        );
      })}
    </ul>
  );
}

export default Sensors;
