import { call, put } from 'redux-saga/effects';

import axios from 'axios';

import { EXPRESS_API_URL } from '../../constants';
import { config, handleApiError } from '../utils';
import { GET_MESSAGES_SUCCESS, SEND_MESSAGE } from '../actions/type';

export function* getMessagesRequest(action) {
  try {
    const { mid } = action.payload;
    const response = yield call(axios.get, `${EXPRESS_API_URL}/messages/get?mid=${mid}`, config);
    yield put({ type: GET_MESSAGES_SUCCESS, payload: response.data });
  }
  catch (error) {
    handleApiError(error, 'getMessages');
  }
}

export function* sendMessageRequest(action) {
  try {
    const { mid, message, name, role } = action.payload;
    const isAdmin = role === 'admin';
    yield call(
      axios.post,
      `${EXPRESS_API_URL}/messages/send`,
      { mid, message, name, isAdmin },
      config,
    );
    const response = yield call(axios.get, `${EXPRESS_API_URL}/messages/get?mid=${mid}`, config);
    yield put({ type: SEND_MESSAGE, payload: response.data });
  }
  catch (error) {
    handleApiError(error, 'getMessages');
  }
}
