import * as actionTypes from '../actions/type';

const INITIAL_STATE = { isFetched: false, isLoading: true, data: [] };

export const sensorslist = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_SENSORS:
      return { ...state, isFetched: true, isLoading: true, data: [] };
    case actionTypes.GET_SENSORS_SUCCESS:
      return { ...state, isFetched: true, isLoading: false, data: action.payload.data };
    default:
      return state;
  }
};
