// reducers/index.js
import { combineReducers } from 'redux';

import { sensors } from './sensors';
import { types } from './types';
import { chart } from './chart';
import { auth } from './auth';
import { timeoptions } from './timeoptions';
import { statehistory } from './statehistory';
import { user } from './user';
import { machines } from './machines';
import { chat } from './chat';
import { annotations } from './annotations';
import { liveStatus } from './liveStatus';
import { logs } from './logs';
import { riskindexesgraph } from './riskindexesgraph';
import { riskindexes } from './riskindexes';
import { activeData } from './activeData';
import { rpmhistorygraph } from './rpmhistorygraph';
import { companies } from './companies';
import { sensorslist } from './sensorslist';
import { periodicrecordsgraph } from './periodicrecordsgraph';
import { multivariateAnomalies } from './multivariateAnomalies';
import { pointidslist } from './pointidslist';
import { userslist } from './userslist';
import { annotationslist } from './annotationslist';
import { gateways } from './gateways';

const rootReducer = combineReducers({
  sensors,
  types,
  chart,
  auth,
  timeoptions,
  statehistory,
  user,
  machines,
  chat,
  annotations,
  liveStatus,
  logs,
  riskindexesgraph,
  riskindexes,
  activeData,
  rpmhistorygraph,
  companies,
  sensorslist,
  periodicrecordsgraph,
  multivariateAnomalies,
  pointidslist,
  userslist,
  annotationslist,
  gateways,
});

export default rootReducer;
