import { Line, ReferenceLine } from 'recharts';

import { createArrayForChart, getRandomColor, yAxisLabelFormatter } from '../../utils';
import Rechart from '../Rechart/Rechart';
import ReferanceLabel from './ReferanceLabel';

export default function SpectrumChart({
  data,
  activeDataType,
  activeType,
  annotations,
  handledHarmonics,
  xAxisMax,
}) {
  const labels = createArrayForChart(data.length, xAxisMax + 1);
  let minY = null;
  let maxY = null;
  const preparedData = [];
  for (let i = 0; i <= labels.length; i++) {
    const yValue = data[i]?.y;
    if (yValue !== undefined) {
      preparedData.push({
        x: labels[i],
        y: yValue,
      });
      if (minY === null || yValue < minY) {
        minY = yValue;
      }
      if (maxY === null || yValue > maxY) {
        maxY = yValue;
      }
    }
  }

  const yAxisLabel = yAxisLabelFormatter(activeDataType, activeType);
  const options = {
    axisLabelisVisible: false,
    legendIsVisible: false,
    axisLabel: {
      x: 'Hz',
      y: yAxisLabel,
    },
    xAxis: {
      type: 'number',
      tickCount: 5,
      maxValue: xAxisMax,
      minValue: 0,
      decimalsInFloat: 0,
    },
    yAxis: {
      tickCount: 10,
      minValue: minY * 2,
      maxValue: maxY * 2,
      decimalsInFloat: 3,
    },
    chartType: activeType,
    showHarmonics: true,
    dataFactor: activeDataType === 'ENV' ? 1.6 : 3.2,
  };
  return (
    <Rechart data={preparedData} options={options} handledHarmonics={handledHarmonics}>
      <Line
        type="monotone"
        strokeWidth={2}
        stroke="#33b2df"
        dataKey="y"
        name={options.axisLabel.y}
        dot={false}
      />
      {annotations.isVisible &&
        annotations.data.map(annotation => {
          const color = getRandomColor();
          return (
            <ReferenceLine
              x={Math.floor(parseFloat(annotation.faultFrequency))}
              key={annotation._id}
              stroke={color}
              label={<ReferanceLabel annotation={annotation} fill={color} />}
            />
          );
        })}
    </Rechart>
  );
}
