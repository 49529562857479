import * as actionTypes from '../actions/type';

export const multivariateAnomalies = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_MULTIVARIATE_ANOMALIES_DATA_SUCCESS:
      return {
        data: {
          ...state.data,
          [action.mid]: {
            ...state.data[action.mid],
            [action.dataType]: {
              ...state.data[action.mid]?.[action.dataType],
              [action.sensorType]: action.payload,
            },
          },
        },
        isLoading: false,
      };
    case actionTypes.GET_MULTIVARIATE_ANOMALIES_DATA:
      return {
        isLoading: true,
        data: { ...state.data },
      };
    default:
      return state;
  }
};
