import axios from 'axios';
import { call, put } from 'redux-saga/effects';

import { EXPRESS_API_URL, EXPRESS_IMAGES_URL } from '../../constants';
import { config, handleApiError } from '../utils';
import {
  GET_ANNOTATIONS_LIST_SUCCESS,
  GET_COMPANIES_SUCCESS,
  GET_GATEWAYS_LIST_SUCCESS,
  GET_POINTIDS_LIST_SUCCESS,
  GET_SENSORS_SUCCESS,
  GET_USERS_LIST_SUCCESS,
} from '../actions/type';

export function* getPointIdsListRequest() {
  try {
    const res = yield call(axios.get, `${EXPRESS_API_URL}/pointIds/list`, { ...config });
    yield put({ type: GET_POINTIDS_LIST_SUCCESS, payload: res.data });
  }
  catch (error) {
    handleApiError(error, 'getPointIdsList');
  }
}

export function* getUsersListRequest() {
  try {
    const res = yield call(axios.get, `${EXPRESS_API_URL}/auth/users/list`, { ...config });
    yield put({ type: GET_USERS_LIST_SUCCESS, payload: res.data });
  }
  catch (error) {
    handleApiError(error, 'getUsersList');
  }
}

export function* getAnnotationsListRequest() {
  try {
    const res = yield call(axios.get, `${EXPRESS_API_URL}/annotations/list`, config);
    yield put({ type: GET_ANNOTATIONS_LIST_SUCCESS, payload: res.data.data });
  }
  catch (error) {
    handleApiError(error, 'getCompanies');
  }
}

export function* getCompaniesListRequest() {
  try {
    const res = yield call(axios.get, `${EXPRESS_API_URL}/companies/list`, config);
    const updatedCompanies = res.data.data.map(companyData => {
      if (companyData?.image?.path) {
        return {
          ...companyData,
          image: {
            ...companyData.image,
            path: `${EXPRESS_IMAGES_URL}/${companyData.image.path}`,
          },
        };
      }
      return companyData;
    });
    yield put({ type: GET_COMPANIES_SUCCESS, payload: updatedCompanies });
  }
  catch (error) {
    handleApiError(error, 'getCompaniesList');
  }
}

export function* getSensorsListRequest() {
  try {
    const res = yield call(axios.get, `${EXPRESS_API_URL}/sensors/list`, { ...config });
    yield put({ type: GET_SENSORS_SUCCESS, payload: res.data });
  }
  catch (error) {
    handleApiError(error, 'getSensorsList');
  }
}

export function* getGatewaysListRequest() {
  try {
    const res = yield call(axios.get, `${EXPRESS_API_URL}/companies/checkGateways`, { ...config });
    yield put({ type: GET_GATEWAYS_LIST_SUCCESS, payload: res.data });
  }
  catch (error) {
    handleApiError(error, 'getGatewaysList');
  }
}
