import { Tab, TabsHeader } from '@material-tailwind/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { setActiveType } from '../../../redux/actions';
import { ChartTabsHeaderSkeleton } from '../../shared/Skeletons';

function ChartTabsHeader() {
  const dispatch = useDispatch();
  const { data: types, isLoading } = useSelector(state => state.types);
  const { activeSensor, activeDataType } = useSelector(state => state.activeData);
  const { t } = useTranslation();

  const activeData = `${activeSensor}_${activeDataType}`;
  if (isLoading) {
    return <ChartTabsHeaderSkeleton />;
  }
  return (
    <TabsHeader className="bg-white m-2">
      {types?.map(type => {
        const typeId = type.id.substring(type.id.indexOf('_') + 1);
        const typeName = type.id.split('_')[0];
        if (typeId === activeData) {
          return (
            <Tab
              key={`Tab_${type.id}`}
              value={type.id}
              onClick={() => dispatch(setActiveType(typeName))}
              active={activeData === type.id ? true : null}
            >
              {t(typeName.toUpperCase())}
            </Tab>
          );
        }
        return null;
      })}
    </TabsHeader>
  );
}

export default ChartTabsHeader;
