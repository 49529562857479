import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

import { Box, LinearProgress, CircularProgress } from '@mui/material';

import { getRiskIndexesChartConfig } from './config';

function RiskIndexes({ machine, highestValue }) {
  const [state, setState] = useState();
  useEffect(() => {
    // temporary
    let newHighestValue = highestValue;
    if (machine === 'ERS 37') {
      newHighestValue = 38;
    }
    if (machine === 'E1_1 Winder') {
      newHighestValue = 40;
    }
    if (newHighestValue > 70) {
      newHighestValue = 70;
    }
    setState(getRiskIndexesChartConfig(newHighestValue));
  }, [machine, highestValue]);

  if (!machine) {
    return <CircularProgress size="6rem" sx={{ marginTop: '2rem', color: '#b0bec5' }} />;
  }

  return (
    <div className="flex flex-col ">
      {state?.series?.length > 0 ? (
        <Chart
          id={`machine${machine}`}
          options={state?.options}
          series={state?.series}
          type="radialBar"
          width="100%"
          height="200px"
        />
      ) : (
        <Box mt={5} sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
    </div>
  );
}

export default RiskIndexes;
