import { Line } from 'recharts';

import Rechart from '../Rechart/Rechart';

function PeriodicRecords({ data }) {
  const yValues = data.reduce(
    (acc, item) => {
      if (item.y > acc.max_y || acc.max_y === undefined) {
        acc.max_y = item.y;
      }
      if (item.y < acc.min_y || acc.min_y === undefined) {
        acc.min_y = item.y;
      }
      acc.total_y += item.y;
      acc.count += 1;

      return acc;
    },
    { max_y: undefined, min_y: undefined, total_y: 0, count: 0 },
  );

  const average = yValues.total_y / yValues.count;
  let minValue = average / 2;
  let maxValue = average * 2;
  if (yValues.max_y > maxValue) {
    maxValue += (yValues.max_y - maxValue) * 1.5;
  }
  if (yValues.min_y < minValue) {
    minValue -= (minValue - yValues.min_y) * 2;
  }

  const trendOptions = {
    axisLabelisVisible: false,
    legendIsVisible: true,
    axisLabel: {
      x: 'Hz',
      y: 'Trend',
    },
    xAxis: {
      type: 'datetime',
      tickCount: 7,
      minValue: data[0].x,
      maxValue: data[data.length - 1].x,
      decimalsInFloat: 0,
    },
    yAxis: {
      tickCount: 7,
      minValue: 0,
      maxValue,
      decimalsInFloat: 2,
    },
    chartType: 'trend',
    timeRangeSelector: true,
    legendVerticalAlign: 'top',
  };
  const periodicOptions = {
    axisLabelisVisible: false,
    legendIsVisible: false,
    axisLabel: {
      x: 'Hz',
      y: trendOptions.yAxisLabel,
    },
    xAxis: {
      type: 'monthly',
      tickCount: 7,
      minValue: data[0].x,
      maxValue: data[data.length - 1].x,
      decimalsInFloat: 0,
    },
    yAxis: trendOptions.yAxis,
    chartType: trendOptions.chartType,
    defaultYAxis: false,
    gridIsVisible: true,
    maxHeight: 300,
    height: 300,
    hideXAxis: false,
    hideZoomReset: false,
    timeRangeSelector: true,
    minimumTimeRangeKey: 2,
    disableZoom: false,
  };
  return (
    <Rechart data={data} options={periodicOptions}>
      <Line
        dataKey="y"
        name="Ortalama Değer"
        connectNulls
        stroke={null}
        dot={{ fill: '#33b2df', r: 4 }}
        isAnimationActive={false}
      />
    </Rechart>
  );
}

export default PeriodicRecords;
