import { Line, ReferenceLine } from 'recharts';

import { sortedIstacMachines, sortedSuborMachines, yAxisLabelFormatter } from '../../utils';
import Rechart from '../Rechart/Rechart';

export default function TrendHeatChart({ data, machine, activeDataType, activeType }) {
  // Temporary
  let allData = data;
  if (machine === 'UF-1A' || machine === 'UF-2B_1') {
    allData = allData.filter(item => {
      const itemDate = new Date(item.x);
      const cutoffDate = new Date('2024-08-13');
      return itemDate >= cutoffDate;
    });
  }
  if (machine === 'UF-2B' || machine === 'UF-2C') {
    allData = allData.filter(item => {
      const itemDate = new Date(item.x);
      const cutoffDate = new Date('2024-08-13');
      return itemDate < cutoffDate;
    });
  }
  // Temporary
  const yAxisLabel = yAxisLabelFormatter(activeDataType, activeType);
  const options = {
    axisLabelisVisible: false,
    legendIsVisible: false,
    xAxisType: 'date',
    axisLabel: {
      x: '',
      y: yAxisLabel,
    },
    xAxis: {
      type: 'datetime',
      tickCount: 7,
      minValue: allData[allData.length - 1].x,
      maxValue: allData[0].x,
      decimalsInFloat: 0,
    },
    yAxis: {
      tickCount: 7,
      minValue: 0,
      maxValue: 80,
      decimalsInFloat: 0,
    },
    chartType: activeType,
    timeRangeSelector: true,
  };
  return (
    <Rechart data={allData} options={options}>
      <Line
        type="monotone"
        strokeWidth={2}
        stroke="#33b2df"
        dataKey="y"
        name={options.axisLabel.y}
        dot={false}
      />
      {sortedSuborMachines.includes(machine) ? (
        <ReferenceLine y={60} strokeDasharray="1 1" strokeOpacity={0.7} stroke="red" />
      ) : null}
      {sortedIstacMachines.includes(machine) ? (
        <ReferenceLine y={60} strokeDasharray="1 1" strokeOpacity={0.7} stroke="red" />
      ) : null}
    </Rechart>
  );
}
