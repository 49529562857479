import StoreIcon from '@mui/icons-material/Store';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import SensorsIcon from '@mui/icons-material/Sensors';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import ReorderIcon from '@mui/icons-material/Reorder';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';

import React from 'react';
import { Group } from '@mui/icons-material';

export const menuItems = [
  {
    label: 'Companies',
    icon: React.createElement(StoreIcon),
    key: 'companies',
  },
  {
    label: 'Machines',
    icon: React.createElement(PrecisionManufacturingIcon),
    key: 'machines',
  },
  {
    label: 'Sensors',
    icon: React.createElement(SensorsIcon),
    key: 'sensors',
  },
  {
    label: 'PointIds',
    icon: React.createElement(LocationSearchingIcon),
    key: 'pointIds',
  },
  {
    label: 'Users',
    icon: React.createElement(Group),
    key: 'users',
  },
  {
    label: 'Logs',
    icon: React.createElement(ReorderIcon),
    key: 'logs',
  },
  {
    label: 'Annotations',
    icon: React.createElement(StackedBarChartIcon),
    key: 'annotations',
  },
];

export const dataTypes = ['RMS', 'ACC', 'ENV', 'TEMP'];
export const types = ['trend', 'spectrum', 'timewave', 'heat'];
export const logsCategories = {
  email: ['tempAlert', 'anomalyAlert', 'riskAlert'],
  cronJob: ['trendMeasurementsJob', 'dynamicMeasurementsJob', 'riskMeasurementsJob'],
};
