import { Skeleton as AntdSkeleton } from 'antd';
import { Skeleton } from '@mui/material';

import React from 'react';
import 'animate.css';

export const ChatSkeleton = () => {
  return (
    <div className="border rounded-lg p-2 mt-5 ml-4 flex flex-col h-60">
      <p className="text-black-900 text-base p-1 rounded-t-lg border-b ">
        <Skeleton variant="text" size="1rem" />
      </p>
      <div className="overflow-y-auto flex flex-col gap-5 h-60 p-2">
        <Skeleton variant="rounded" width={300} height={50} animation="wave" />
        <Skeleton variant="rounded" width={300} height={50} animation="wave" className="ml-auto" />
      </div>
      <div className="flex items-center border-t p-2">
        <div className="w-full mx-1">
          <Skeleton variant="rounded" height={40} />
        </div>
      </div>
    </div>
  );
};

export const SensorsSkeleton = () => {
  return (
    <div className="w-full h-60 flex justify-between gap-5">
      {Array(5)
        .fill()
        .map(() => (
          <Skeleton
            variant="rounded"
            width={200}
            height={200}
            animation="wave"
            key={Math.random()}
          />
        ))}
    </div>
  );
};

export const ChartSkeleton = ({ height = 320 }) => {
  return (
    <div className="flex justify-center items-center flex-col gap-5" style={{ width: '100%', height }}>
      <img
        className="animate__animated animate__bounce animate animate__infinite"
        src="/assets/chart_loading.svg"
        width="150"
        height="150"
        alt="Chart Skeleton"
      />
    </div>
  );
};

export const PhotoSliderSkeleton = () => {
  return (
    <div className="w-full h-full flex justify-center items-center" data-testid="photo-slider">
      <Skeleton
        variant="rounded"
        width={200}
        height={100}
        animation="wave"
      />
    </div>
  );
};

export const CompanySkeleton = () => {
  return (
    <div className="col-span-4 h-80 border rounded-lg m-5 p-5" data-testid="company-skeleton">
      <AntdSkeleton active />
    </div>
  );
};

export const ChartTabsHeaderSkeleton = () => {
  return (
    <div className="col-span-8 p-10 justify-between flex flex-row gap-5">
      {
        Array(3).fill().map(index => (
          <Skeleton
            variant="rounded"
            key={index}
            width={300}
            height={30}
            animation="wave"
          />
        ))
      }
    </div>
  );
};
