import * as actionTypes from '../actions/type';

export const timeoptions = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_TIME_OPTIONS:
      return { isLoading: true, timeoptions: [] };
    case actionTypes.GET_TIME_OPTIONS_SUCCESS:
      return { isLoading: false, timeOptions: action.payload };
    case actionTypes.SET_ACTIVE_OPTION:
      return {
        isLoading: false,
        timeOptions: state.timeOptions.map(option => (option.ReadingTimeUTC === action.selected
          ? {
            ...option,
            active: true,
          }
          : {
            ...option,
            active: false,
          })),
      };
    default:
      return state;
  }
};
