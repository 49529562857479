import React from 'react';

import { NewAnnotationForm } from './Forms';

function AnnotationSave() {
  return (
    <div>
      <NewAnnotationForm />
    </div>
  );
}

export default AnnotationSave;
