import * as actionTypes from '../actions/type';

export const liveStatus = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_LIVE_STATUS:
      return { ...state, isLoading: true };
    case actionTypes.GET_LIVE_STATUS_SUCCESS:
      return { ...state, isLoading: false, [action.mid]: action.payload };
    default:
      return state;
  }
};
