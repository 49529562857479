import { Button, Checkbox, Form, InputNumber, message, Popconfirm, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { useEffect, useState } from 'react';

import { getUser } from '../../../../redux/actions';
import { dataTypes, types } from '../../constants';
import { authDeleteRequest, authPostRequest } from '../../../../services/api-service';

export function NewPointIdsForm() {
  const { isFetched, isLoading } = useSelector(state => state.user);
  const machinesList = useSelector(state => state.user.data.machine).map(
    machine => machine?.mid,
  );
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (!isFetched) {
      dispatch(getUser());
    }
  }, [dispatch, isFetched]);

  const onFinish = async formData => {
    const requestData = formData.types.map(type => ({
      mid: formData.mid,
      sid: String(formData.sid),
      type,
      dataType: formData.dataType,
      pointId: formData.pointId,
    }));
    const response = await authPostRequest('pointIds/insertMany', requestData);
    if (response.statusCode === 200) {
      messageApi.open({
        type: 'success',
        content: 'PointIds has been added.',
      });
    }
    else {
      messageApi.open({
        type: 'error',
        content: response.message || response.statusCode,
      });
    }
  };

  return (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 600 }}
      initialValues={{ dataTypes }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item label="Machine" name="mid" required>
        <Select
          style={{ width: 220 }}
          options={machinesList?.map(mid => ({ label: mid, value: mid }))}
          loading={isLoading}
        />
      </Form.Item>
      <Form.Item label="Data Type" name="dataType" required>
        <Select
          style={{ width: 220 }}
          options={dataTypes.map(dataType => ({ label: dataType, value: dataType }))}
        />
      </Form.Item>
      <Form.Item label="Sensor Id" name="sid" required>
        <InputNumber />
      </Form.Item>
      <Form.Item label="Point Id" name="pointId" required>
        <InputNumber />
      </Form.Item>
      <Form.Item label="Types" name="types" required>
        <Checkbox.Group options={types.map(type => ({ label: type, value: type }))} />
      </Form.Item>
      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
      {contextHolder}
    </Form>
  );
}

export function DeletePointIdForm({ data }) {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const showPopconfirm = () => {
    setOpen(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);
    const response = await authDeleteRequest('pointIds/delete', data._id);
    if (response.statusCode === 200) {
      messageApi.open({
        type: 'success',
        content: 'PointId has been deleted.',
      });
      setOpen(false);
      setConfirmLoading(false);
    }
    else {
      messageApi.open({
        type: 'error',
        content: response.message || response.statusCode,
      });
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };
  return (
    <Popconfirm
      title="Delete the point id"
      description="Are you sure to delete this point id?"
      open={open}
      onConfirm={handleOk}
      okButtonProps={{ loading: confirmLoading }}
      onCancel={handleCancel}
    >
      <Button danger onClick={showPopconfirm}>
        Delete
      </Button>
      {contextHolder}
    </Popconfirm>
  );
}
