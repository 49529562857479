import {
  GET_CHART_DATA,
  GET_MULTIVARIATE_ANOMALIES_DATA,
  GET_TYPES,
  SET_ACTIVE_SENSOR,
  LOGIN,
  LOGOUT,
  GET_TIME_OPTIONS,
  SET_ACTIVE_OPTION,
  GET_STATE_HISTORY,
  GET_USER_DATA,
  GET_MACHINE_DATA,
  GET_MESSAGES,
  SEND_MESSAGE,
  GET_ANNOTATIONS,
  SHOW_HIDE_ANNOTATIONS,
  GET_LIVE_STATUS,
  SAVE_LOG,
  GET_RISK_INDEXES,
  GET_RISK_INDEXES_GRAPH,
  SET_ACTIVE_DATATYPE,
  SET_ACTIVE_TYPE,
  GET_RPM_HISTORY_GRAPH,
  GET_COMPANIES,
  GET_SENSORS_DATA,
  GET_SENSORS,
  GET_PERIODIC_RECORDS_GRAPH,
  GET_POINTIDS_LIST,
  GET_USERS_LIST,
  GET_LOGS,
  GET_ANNOTATIONS_LIST,
  GET_GATEWAYS_LIST,
} from './type';

export const getMessages = mid => ({
  type: GET_MESSAGES,
  payload: { mid },
});

export const sendMessage = (mid, message, name, role) => ({
  type: SEND_MESSAGE,
  payload: { mid, message, name, role },
});

export const getChartData = (mid, sid, type, dataType, ReadingTimeUTC = null, last = null) => ({
  type: GET_CHART_DATA,
  payload: {
    mid,
    sid,
    type,
    dataType,
    ReadingTimeUTC,
    last,
  },
});

export const getMultivariateAnomaliesData = (mid, type, dataType) => ({
  type: GET_MULTIVARIATE_ANOMALIES_DATA,
  payload: {
    mid,
    type,
    dataType,
  },
});

export const getLiveStatus = mid => ({
  type: GET_LIVE_STATUS,
  payload: { mid },
});

export const getUser = mid => ({
  type: GET_USER_DATA,
  payload: { mid },
});

export const getMachines = () => ({ type: GET_MACHINE_DATA });

export const getStateHistory = mid => ({
  type: GET_STATE_HISTORY,
  payload: { mid },
});

export const getChartTypes = mid => ({
  type: GET_TYPES,
  payload: { mid },
});

export const getAnnotations = mid => ({
  type: GET_ANNOTATIONS,
  payload: { mid },
});

export const showHideAnnotations = isVisible => ({
  type: SHOW_HIDE_ANNOTATIONS,
  payload: { isVisible },
});

export const setTimeOption = option => ({
  type: SET_ACTIVE_OPTION,
  selected: option,
});

export const getTimeOptions = (mid, sid, type, dataType) => ({
  type: GET_TIME_OPTIONS,
  payload: {
    mid,
    sid,
    type,
    dataType,
  },
});

export const getRiskIndexes = mid => ({
  type: GET_RISK_INDEXES,
  payload: { mid },
});

export const getRiskIndexesGraph = mid => ({
  type: GET_RISK_INDEXES_GRAPH,
  payload: { mid },
});

export const login = (email, password) => ({
  type: LOGIN,
  payload: {
    email,
    password,
  },
});

export const logut = () => ({ type: LOGOUT });

export const saveLog = (log, userInfo, machine) => ({
  type: SAVE_LOG,
  payload: {
    log,
    userInfo,
    machine,
  },
});

export const getLogs = ({ skip, limit, ...restFilters }) => ({
  type: GET_LOGS,
  filters: { skip, limit, ...restFilters },
  payload: null,
});

export const setActiveSensor = sensor => ({
  type: SET_ACTIVE_SENSOR,
  sensor,
});

export const setActiveType = sensorType => ({
  type: SET_ACTIVE_TYPE,
  sensorType,
});

export const setActiveDataType = dataType => ({
  type: SET_ACTIVE_DATATYPE,
  dataType,
});

export const getSensorsData = mid => ({
  type: GET_SENSORS_DATA,
  payload: { mid },
});

export const getRpmHistoryGraph = mid => ({
  type: GET_RPM_HISTORY_GRAPH,
  payload: { mid },
});

export const getCompanies = () => ({
  type: GET_COMPANIES,
  payload: null,
});

export const getSensors = data => ({
  type: GET_SENSORS,
  payload: data,
});

export const getPeriodicRecordsGraph = (mid, sid, type, dataType) => ({
  type: GET_PERIODIC_RECORDS_GRAPH,
  payload: { mid, sid, type, dataType },
});

export const getPointIdsList = () => ({
  type: GET_POINTIDS_LIST,
  payload: null,
});

export const getUsersList = () => ({
  type: GET_USERS_LIST,
  payload: null,
});

export const getAnnotationsList = () => ({
  type: GET_ANNOTATIONS_LIST,
  payload: null,
});

export const getGatewaysList = () => ({
  type: GET_GATEWAYS_LIST,
  payload: null,
});
