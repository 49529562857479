import * as actionTypes from '../actions/type';

export const chart = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_CHART_DATA_SUCCESS:
      return {
        data: {
          ...state.data,
          [action.mid]: {
            ...state.data[action.mid],
            [action.sensorType]: {
              ...state.data[action.mid]?.[action.sensorType],
              [action.sid]: {
                ...state.data[action.mid]?.[action.sensorType]?.[action.sid],
                [action.dataType]: action.payload,
              },
            },
          },
        },
        isLoading: false,
      };
    case actionTypes.GET_CHART_DATA:
      return {
        isLoading: true,
        data: { ...state.data },
      };
    default:
      return state;
  }
};
