import { useState } from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { PhotoSliderSkeleton } from '../../shared/Skeletons';

function PhotoSlider() {
  const machinesLoading = useSelector(state => state.user.isLoading);
  const machinesFetched = useSelector(state => state.user.isFetched);
  const images = useSelector(state => state.user.data.machine?.[0]?.images);

  const { t } = useTranslation();
  const [selectedImage, setSelectedImage] = useState({});

  const openModal = image => setSelectedImage(image);
  const closeModal = () => setSelectedImage(null);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  if (!machinesFetched) {
    return null;
  }
  if (machinesLoading) {
    return <PhotoSliderSkeleton />;
  }

  return (
    <div>
      <Slider {...settings} className="px-5 my-5 w-40">
        {images.map(image => (
          <div className="mx-auto w-auto" key={image.originalname}>
            <img
              className="h-36 cursor-pointer"
              src={image.path}
              alt={image.originalname}
              onClick={() => openModal(image)}
            />
          </div>
        ))}
      </Slider>
      {selectedImage?.path && (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="max-w-md mx-auto p-4 bg-white rounded-lg">
          <img src={selectedImage.path} alt="Fotoğraf" className="w-full" />
          <button
            type="button"
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            onClick={closeModal}
          >
            {t('CLOSE')}
          </button>
        </div>
      </div>
      )}
    </div>
  );
}

export default PhotoSlider;
