import { Area, ReferenceLine } from 'recharts';
import { useParams } from 'react-router-dom';

import { useMemo } from 'react';

import Rechart from '../Rechart/Rechart';

export default function RiskTrendChart({ data, minimize = false }) {
  // Temporary
  const { machine } = useParams();
  let allData = data;
  if (machine === 'UF-1A' || machine === 'UF-2B_1') {
    allData = allData.filter(item => {
      const itemDate = new Date(item[0]);
      const cutoffDate = new Date('2024-08-13');
      return itemDate >= cutoffDate;
    });
  }
  if (machine === 'UF-2B' || machine === 'UF-2C') {
    allData = allData.filter(item => {
      const itemDate = new Date(item[0]);
      const cutoffDate = new Date('2024-08-13');
      return itemDate < cutoffDate;
    });
  }
  const preparedData = useMemo(() => {
    return allData.map(item => ({ y: item[1], x: new Date(item[0]).getTime() }));
  }, [allData]);
  const options = {
    height: minimize ? 180 : 300,
    axisLabelisVisible: minimize,
    legendIsVisible: false,
    axisLabel: {
      x: '',
      y: 'Risk Skoru',
      fontSize: 16,
    },
    xAxis: {
      type: 'date',
      tickCount: minimize ? 4 : 8,
      minValue: preparedData[0]?.x,
      maxValue: preparedData[preparedData.length - 1]?.x,
    },
    yAxis: {
      tickCount: minimize ? 5 : 10,
      minValue: 0,
      maxValue: 100,
      decimalsInFloat: 0,
    },
    chartType: 'risk',
    gridIsVisible: false,
    showToolBox: !minimize,
    color: minimize ? 'white' : undefined,
  };
  return ((
    <Rechart data={preparedData} options={options}>
      <Area
        type="monotone"
        dataKey="y"
        name="Risk Skoru"
        stroke={null}
        fill={minimize ? '#8099AD' : '#e5e7eb'}
        fillOpacity={1}
      />
      <ReferenceLine y={35} strokeDasharray="1 1" strokeOpacity={0.7} stroke="orange" />
      <ReferenceLine y={65} strokeDasharray="1 1" strokeOpacity={0.7} stroke="red" />
    </Rechart>
  )
  );
}
