import axios from 'axios';

import authHeader from './auth-header';

const API_URL = 'https://pdmapi.azurewebsites.net/V1/';
// const API_URL = 'https://pd-development.azurewebsites.net/V1/';

class LogService {
  saveLog(log, userInfo, machine) {
    const config = {
      headers: {
        Authorization: authHeader().Authorization,
        'Content-Type': 'application/json',
      },
    };
    return axios.post(`${API_URL}savelog`, { log, userInfo, machine }, config);
  }

  getLogs() {
    const config = {
      headers: {
        Authorization: authHeader().Authorization,
        'Content-Type': 'application/json',
      },
    };
    return axios.get(`${API_URL}logs`, config);
  }
}

export default new LogService();
