// eslint-disable-next-line import/no-extraneous-dependencies
import { scaleLinear } from 'd3-scale';
import i18next from 'i18next';
import moment from 'moment';

export const formatFloat = (e, decimalsInFloat) => {
  if (decimalsInFloat === 0) {
    return Math.round(e);
  }
  const parts = e.toString().split('.');
  const integerPart = parts[0];
  let decimalPart = parts[1] || '';
  if (decimalPart.length > decimalsInFloat) {
    decimalPart = decimalPart.slice(0, decimalsInFloat);
    return `${integerPart}.${decimalPart}`;
  }
  while (decimalPart.length < decimalsInFloat) {
    decimalPart += '0';
  }
  return decimalPart !== '' ? `${integerPart}.${decimalPart}` : integerPart;
};

export const isDateWithin2Days = (date1, date2) => {
  const differenceInSeconds = Math.abs(Math.floor((date1 - date2) / 1000));
  const twoDaysInSeconds = 2 * 24 * 3600;
  return differenceInSeconds < twoDaysInSeconds;
};

export const generateXAxisValues = ([minValue, maxValue], refAreaLeft, refAreaRight, count = 8) => {
  const left = refAreaLeft !== '' ? refAreaLeft : minValue;
  const right = refAreaRight !== '' ? refAreaRight : maxValue;
  const array = [];
  const x = scaleLinear([0, count], [left, right]).clamp(false);
  for (let i = 0; i <= count; i++) {
    array[i] = x(i);
  }
  array[0] = refAreaLeft !== '' ? refAreaLeft : array[0];
  array[array.length - 1] = refAreaRight !== '' ? refAreaRight : array[array.length - 1];
  return array;
};

export const generateYAxisValues = ([min, max], count = 10) => {
  const array = [];
  const ticks = scaleLinear([0, count], [min, max]).clamp(false);
  for (let i = 0; i <= count; i++) {
    array[i] = ticks(i);
  }
  return array;
};

export const formatDate = (tick, type) => {
  const months = [
    'JANUARY',
    'FEBRUARY',
    'MARCH',
    'APRIL',
    'MAY',
    'JUNE',
    'JULY',
    'AUGUST',
    'SEPTEMBER',
    'OCTOBER',
    'NOVEMBER',
    'DECEMBER',
  ];
  const formattedDate = new Date(tick);
  const month = months[formattedDate.getMonth()];
  const day = formattedDate.getDate();
  const year = formattedDate.getFullYear();
  if (type === 'monthly') {
    return `${year.toString().substring(2, 4)} ${i18next.t(month).substring(0, 3)}`;
  }
  const translatedMonth = i18next.t(month).substring(0, 3);
  return `${translatedMonth} '${day}`;
};

export const tooltipLabelFormatter = (e, payload, xAxis) => {
  const { type, decimalsInFloat, tooltipType = type } = xAxis;
  if (tooltipType === 'date') {
    return moment(e).format('YYYY-MM-DD');
  }
  if (tooltipType === 'datetime') {
    return moment(e).format('YYYY-MM-DD HH:mm:ss');
  }
  if (tooltipType === 'number') {
    return formatFloat(e, decimalsInFloat + 1);
  }
  if (tooltipType === 'monthly') {
    return moment(e).format('YYYY-MM');
  }

  return e;
};

export const harmonicGenerator = value => {
  const array = [];
  for (let i = 0; i < 10; i++) {
    array.push(Math.round(value * (i + 1)));
  }
  return array;
};

export const tooltipFormatter = (e, decimalsInFloat, props) => {
  const { payload } = props;
  if (e?.length > 1) {
    if (e[0] === undefined && e[1] === undefined) {
      return '-';
    }
    return `${formatFloat(e[0], decimalsInFloat)}-${formatFloat(e[1], decimalsInFloat)}`;
  }
  let validDate;
  if (props.dataKey.includes('.value')) {
    const key = props.dataKey.split('.')[0];
    validDate = payload[key].validDate;
  }
  const result = validDate
    ? `${formatFloat(e, decimalsInFloat)} - ${validDate}`
    : `${formatFloat(e, decimalsInFloat)}`;
  return result;
};

export const tickFormatter = (tick, index, xTicks, xAxis) => {
  if (xAxis.type === 'date') {
    return formatDate(tick);
  }
  if (xAxis.type === 'datetime') {
    if (xTicks?.length === xAxis.tickCount + 1) {
      if (isDateWithin2Days(xTicks[0], xTicks[xTicks.length - 1])) {
        return moment(xTicks[index]).format('HH:00');
      }
    }
    return formatDate(tick);
  }
  if (xAxis.type === 'number') {
    return formatFloat(tick, xAxis.decimalsInFloat);
  }
  if (xAxis.type === 'monthly') {
    return formatDate(tick, 'monthly');
  }
  return tick;
};
