import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';

import { getRiskIndexesGraph } from '../../../redux/actions';
import RiskTrendChart from '../../Machines/components/Charts/RiskTrendChart';

function RiskOverTime({ machine }) {
  const dispatch = useDispatch();
  const riskindexesgraph = useSelector(state => state?.riskindexesgraph);
  let data = riskindexesgraph[machine]?.data;
  const isLoading = riskindexesgraph[machine]?.isLoading;
  const isFetched = riskindexesgraph[machine]?.isFetched;
  useEffect(() => {
    if (!isFetched) {
      dispatch(getRiskIndexesGraph(machine));
    }
  }, [dispatch, isFetched, machine]);
  if (isLoading || !data) {
    return null;
  }
  if (machine === 'Gelecek Makine') return <div />;

  // Temporary
  if (machine === 'UF-1A' || machine === 'UF-2B_1') {
    data = data.filter(item => {
      const itemDate = new Date(item[0]);
      const cutoffDate = new Date('2024-08-13');
      return itemDate >= cutoffDate;
    });
  }
  if (machine === 'UF-2B' || machine === 'UF-2C') {
    data = data.filter(item => {
      const itemDate = new Date(item[0]);
      const cutoffDate = new Date('2024-08-13');
      return itemDate < cutoffDate;
    });
  }
  // Temporary

  return (
    <div id="area-datetime" data-testid="area-datetime" className="p-1 m-0 w-full">
      <RiskTrendChart data={data} minimize />
    </div>
  );
}

export default RiskOverTime;
