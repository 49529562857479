import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { getRpmHistoryGraph } from '../../../redux/actions';
import { ChartSkeleton } from '../../shared/Skeletons';
import RpmHistoryChart from './Charts/RpmHistoryChart';

function RpmHistory() {
  const { machine } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const rpmHistoryGraph = useSelector(state => state?.rpmhistorygraph);
  const data = rpmHistoryGraph[machine]?.data;
  const isLoading = rpmHistoryGraph[machine]?.isLoading;

  useEffect(() => {
    dispatch(getRpmHistoryGraph(machine));
  }, [dispatch, machine]);

  if (isLoading || !data) {
    return <ChartSkeleton />;
  }
  return (
    <div className="col-span-4 ml-4 mt-5 flex bg-white border rounded-lg flex-col md:max-w-xl dark:border-gray-700 dark:bg-gray-800 h-80">
      <div className="grid grid-cols-1">
        <div className="grid grid-cols-3">
          <div className="px-2 pt-2 col-span-12 text-lg font-bold tracking-tight text-gray-900 dark:text-white">
            {t('RPM_HISTORY')}
          </div>
        </div>
      </div>
      <div id="area-datetime" className="py-0 px-1  w-full h-full">
        {data?.length > 1 && <RpmHistoryChart data={data} />}
      </div>
    </div>
  );
}

export default RpmHistory;
