import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { generateXAxisValues } from './utils';

export default function TimeRange({ yTicks, setState, xAxis, minimumTimeRangeKey }) {
  const { t } = useTranslation();
  const initialState =
    Math.abs(new Date(xAxis.minValue) - new Date(xAxis.maxValue)) >= 30 * 24 * 60 * 60 * 1000
      ? 1
      : 5;

  const [time, setTime] = useState(minimumTimeRangeKey === 2 ? 5 : initialState);

  const changeTime = e => {
    const value = e.target.value || 0;
    setTime(value);
  };
  useEffect(() => {
    setState(prev => {
      const today = new Date(xAxis.maxValue);
      let value = new Date();
      switch (time) {
        case 0:
          value = today.setDate(today.getDate() - 7);
          break;
        case 1:
          value = today.setMonth(today.getMonth() - 1);
          break;
        case 2:
          value = today.setMonth(today.getMonth() - 3);
          break;
        case 3:
          value = today.setMonth(today.getMonth() - 6);
          break;
        case 4:
          value = today.setFullYear(today.getFullYear() - 1);
          break;
        case 5:
          value = new Date(xAxis.minValue);
          break;
        default:
          break;
      }
      return {
        ...prev,
        left: value,
        right: today,
        xTicks: generateXAxisValues([value, xAxis.maxValue], '', ''),
      };
    });
  }, [time, yTicks, setState, xAxis]);
  const activeTime = 'bg-gray-50 rounded';
  return (
    <ul className="flex gap-2 p-1 bg-gray-100 rounded-md cursor-pointer" onClick={changeTime}>
      {minimumTimeRangeKey === 0 && (
        <li className={`text-xs px-1 ${time === 0 ? activeTime : ''}`} value={0}>
          1 {t('WEEK')}
        </li>
      )}
      {(minimumTimeRangeKey === 1 || minimumTimeRangeKey === 0) && (
        <li className={`text-xs px-1 ${time === 1 ? activeTime : ''}`} value={1}>
          1 {t('MONTH')}
        </li>
      )}
      <li className={`text-xs px-1 ${time === 2 ? activeTime : ''}`} value={2}>
        3 {t('MONTH')}
      </li>
      <li className={`text-xs px-1 ${time === 3 ? activeTime : ''}`} value={3}>
        6 {t('MONTH')}
      </li>
      <li className={`text-xs px-1 ${time === 4 ? activeTime : ''}`} value={4}>
        1 {t('YEAR')}
      </li>
    </ul>
  );
}
