import { Button, Form, Input, InputNumber, Popconfirm, Select, Upload, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { UploadOutlined } from '@mui/icons-material';

import { getCompanies } from '../../../../redux/actions/index';
import {
  authDeleteRequest,
  authPostRequest,
  authPutRequest,
} from '../../../../services/api-service';
import { uploadConfig } from '../../utils';

export function NewMachineForm() {
  const dispatch = useDispatch();
  const companiesFetched = useSelector(state => state.companies.isFetched);
  const companiesData = useSelector(state => state.companies.data);
  const [imageIds, setImageIds] = useState([]);
  const [selectableFactories, setSelectableFactories] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  useEffect(() => {
    if (!companiesFetched) {
      dispatch(getCompanies());
    }
  }, [companiesFetched, dispatch]);
  const selectedCompanyChanged = selectedCompany => {
    const filteredFactories = companiesData.find(
      companyData => companyData._id === selectedCompany,
    ).factories;
    setSelectableFactories(filteredFactories);
  };
  const onFinish = async data => {
    const requestData = { ...data, images: imageIds };
    const response = await authPostRequest('machines/new', requestData);
    if (response.statusCode === 200) {
      messageApi.open({
        type: 'success',
        content: `${response.data.mid} has been added.`,
      });
    }
    else {
      messageApi.open({
        type: 'error',
        content: response.message || response.statusCode,
      });
    }
  };
  const handleImagesChange = info => {
    if (info.file.status === 'done') {
      const { originalname, path, _id } = info.file.response.data;
      setImageIds(prevState => [...prevState, _id]);
      message.success(`${originalname} file uploaded to ${path} successfully`);
    }
    else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };
  const handleImageRemove = async info => {
    const id = info.response.data._id;
    await authDeleteRequest('media', id);
  };
  return (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 600 }}
      initialValues={{ rpm: '3000' }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item label="Machine ID" name="mid" required>
        <Input placeholder="ERS 55, 12-Q9, 12-Q10, UF-2D" />
      </Form.Item>
      <Form.Item label="Company" name="company" required>
        <Select
          style={{ width: 220 }}
          onChange={selectedCompanyChanged}
          options={companiesData?.map(companyData => ({
            value: companyData._id,
            label: companyData.company,
          }))}
        />
      </Form.Item>
      <Form.Item label="Factory" name="factory" required>
        <Select
          style={{ width: 220 }}
          options={selectableFactories?.map(factory => ({ label: factory, value: factory }))}
        />
      </Form.Item>
      <Form.Item label="Rpm" name="rpm">
        <Input />
      </Form.Item>
      <Form.Item label="Visible Name" name="visibleName">
        <Input placeholder="Grup|12-Q9, Kompresör|RSN 75E, " />
      </Form.Item>
      <Form.Item label="Anomaly Exclude Threshold" name="anomalyExcludeThreshold">
        <InputNumber step={0.01} />
      </Form.Item>
      <Form.Item label="Images">
        <Upload
          {...uploadConfig}
          name="file"
          multiple
          maxCount={5}
          listType="picture"
          onChange={handleImagesChange}
          onRemove={handleImageRemove}
        >
          <Button icon={<UploadOutlined />}>Upload (Max: 5)</Button>
        </Upload>
      </Form.Item>
      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
      {contextHolder}
    </Form>
  );
}

export function EditMachineForm({ editData }) {
  const imageList = editData.images.map((imageInfo, index) => ({
    _id: imageInfo._id,
    ui: index,
    name: imageInfo.filename,
    url: imageInfo.path,
    thumbUrl: imageInfo.path,
    status: 'done',
  }));
  const dispatch = useDispatch();
  const companiesFetched = useSelector(state => state.companies.isFetched);
  const companiesData = useSelector(state => state.companies.data);
  const [selectableFactories, setSelectableFactories] = useState(null);
  const [imageIds, setImageIds] = useState(editData.images.map(image => image._id));
  const [messageApi, contextHolder] = message.useMessage();
  useEffect(() => {
    if (!companiesFetched) {
      dispatch(getCompanies());
    }
  }, [companiesFetched, dispatch]);
  const selectedCompanyChanged = selectedCompany => {
    const filteredFactories = companiesData.find(
      companyData => companyData._id === selectedCompany,
    ).factories;
    setSelectableFactories(filteredFactories);
  };
  const onFinish = async data => {
    const requestData = data;
    requestData.images = imageIds;
    requestData.company = data.company.value;
    const response = await authPutRequest('machines/update', editData._id, requestData);
    if (response.statusCode === 200) {
      messageApi.open({
        type: 'success',
        content: `${response.data.mid} has been updated.`,
      });
    }
    else {
      messageApi.open({
        type: 'error',
        content: response.message || response.statusCode,
      });
    }
  };

  const handleImagesChange = async info => {
    if (info.file.status === 'done') {
      const { originalname, path, _id } = info.file.response.data;
      setImageIds(prevState => [...prevState, _id]);
      message.success(`${originalname} file uploaded to ${path} successfully`);
    }
    else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };
  const handleImageRemove = async info => {
    const id = info.response ? info.response.data._id : info._id;
    setImageIds(prev => prev.filter(_id => _id !== id));
    await authDeleteRequest('media', id);
  };
  return (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 600 }}
      initialValues={{
        ...editData,
        company: { value: editData.company._id, label: editData.company.company },
      }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item label="Machine ID" name="mid" required>
        <Input placeholder="ERS 55, 12-Q9, 12-Q10, UF-2D" />
      </Form.Item>
      <Form.Item label="Company" name="company" required>
        <Select
          style={{ width: 220 }}
          onChange={selectedCompanyChanged}
          options={companiesData?.map(companyData => ({
            value: companyData._id,
            label: companyData.company,
          }))}
        />
      </Form.Item>
      <Form.Item label="Factory" name="factory" required>
        <Select
          style={{ width: 220 }}
          options={selectableFactories?.map(factory => ({ label: factory, value: factory }))}
        />
      </Form.Item>
      <Form.Item label="Rpm" name="rpm">
        <Input />
      </Form.Item>
      <Form.Item label="Visible Name" name="visibleName">
        <Input placeholder="Grup|12-Q9, Kompresör|RSN 75E, " />
      </Form.Item>
      <Form.Item label="Anomaly Exclude Threshold" name="anomalyExcludeThreshold">
        <InputNumber step={0.01} />
      </Form.Item>
      <Form.Item label="Images" name="images">
        <Upload
          {...uploadConfig}
          name="file"
          multiple
          maxCount={5}
          listType="picture"
          onChange={handleImagesChange}
          onRemove={handleImageRemove}
          defaultFileList={[...imageList]}
        >
          <Button icon={<UploadOutlined />}>Upload (Max: 5)</Button>
        </Upload>
      </Form.Item>
      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
      {contextHolder}
    </Form>
  );
}

export function DeleteMachineForm({ data }) {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const showPopconfirm = () => {
    setOpen(true);
  };
  const handleOk = async () => {
    setConfirmLoading(true);
    const response = await authDeleteRequest('machines/delete', data._id);
    if (response.statusCode === 200) {
      messageApi.open({
        type: 'success',
        content: 'Machine has been deleted.',
      });
      setOpen(false);
      setConfirmLoading(false);
    }
    else {
      messageApi.open({
        type: 'error',
        content: response.message || response.statusCode,
      });
    }
  };
  const handleCancel = () => {
    setOpen(false);
  };
  return (
    <Popconfirm
      title="Delete the machine"
      description="Are you sure to delete this machine?"
      open={open}
      onConfirm={handleOk}
      okButtonProps={{ loading: confirmLoading }}
      onCancel={handleCancel}
    >
      <Button danger onClick={showPopconfirm}>
        Delete
      </Button>
      {contextHolder}
    </Popconfirm>
  );
}
