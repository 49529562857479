import { TabPanel, TabsBody } from '@material-tailwind/react';
import React from 'react';

import { useSelector } from 'react-redux';

import { LinearProgress } from '@mui/material';

import TimeOptions from './TimeOptions';
import AnnotationSwitch from './AnnotationSwitch';
import ChartContainer from './ChartContainer';

function ChartTabsBody() {
  const { activeType, activeSensor, activeDataType } = useSelector(state => state.activeData);
  const { data: types, isLoading } = useSelector(state => state.types);
  if (isLoading || !types) {
    return <LinearProgress />;
  }
  return (
    <TabsBody>
      {types?.map(type => {
        if (type.id === `${activeType}_${activeSensor}_${activeDataType}`) {
          return (
            <TabPanel key={`TabPanel_${type.id}`} value={type.id}>
              <div className="grid grid-cols-3 gap-12">
                {activeType === 'spectrum' || activeType === 'timewave' ? <TimeOptions /> : null}
                {activeType === 'spectrum' ? <AnnotationSwitch /> : null}
              </div>
              <ChartContainer />
            </TabPanel>
          );
        }
        return null;
      })}
    </TabsBody>
  );
}

export default ChartTabsBody;
