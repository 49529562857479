import React from 'react';

import { NewSensorForm } from './Forms';

function SensorSave() {
  return (
    <div>
      <NewSensorForm />
    </div>
  );
}

export default SensorSave;
