import React from 'react';
import { Button, Table } from 'antd';
import { Link } from 'react-router-dom';

import { DeleteMachineForm } from '../Forms';

const MachinesListTable = ({ data }) => {
  const machineFilter = [...new Set(data.map(machineData => machineData.mid))].map(mid => ({
    text: mid,
    value: mid,
  }));
  const companyFilter = [...new Set(data.map(machineData => machineData.company.company))].map(
    company => {
      return { text: company, value: company };
    },
  );
  const columns = [
    {
      title: 'Mid',
      dataIndex: 'mid',
      key: 'mid',
      width: 100,
      fixed: 'left',
      filters: machineFilter,
      onFilter: (value, record) => record.mid === value,
    },
    {
      title: 'Visible Name',
      dataIndex: 'visibleName',
      key: 'visibleName',
      width: 150,
    },
    {
      title: 'Company',
      width: 100,
      dataIndex: 'company',
      key: 'company',
      render: item => item.company,
      filters: companyFilter,
      onFilter: (value, record) => value === record.company.company,
    },
    {
      title: 'Factory',
      width: 100,
      dataIndex: 'factory',
      key: 'factory',
    },
    {
      title: 'Anomaly Exclude Threshold',
      width: 100,
      dataIndex: 'anomalyExcludeThreshold',
      key: 'anomalyExcludeThreshold',
    },
    {
      title: 'Rpm',
      width: 100,
      dataIndex: 'rpm',
      key: 'rpm',
    },
    {
      title: 'Action',
      key: 'operation',
      width: 100,
      render: item => (
        <div className="flex gap-2">
          <Button>
            <Link to={`/admin/machines/update/${item._id}`}>Edit</Link>
          </Button>
          <DeleteMachineForm data={item} />
        </div>
      ),
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={data.map((item, index) => ({ key: index, ...item }))}
      className="min-h-screen h-full"
      scroll={{ x: 1300 }}
    />
  );
};

export default MachinesListTable;
