/* eslint-disable no-console */
import axios from 'axios';

import { config } from '../redux/utils';

import { EXPRESS_API_URL } from '../constants';

export const authGetRequest = async endpoint => {
  try {
    const response = await axios.get(`${EXPRESS_API_URL}/${endpoint}`, config);
    return response.data;
  }
  catch (error) {
    console.error('Error during GET request:', error);
    return error;
  }
};

export const authPostRequest = async (endpoint, data) => {
  try {
    const response = await axios.post(`${EXPRESS_API_URL}/${endpoint}`, data, config);
    return response.data;
  }
  catch (error) {
    console.error('Error during POST request:', error);
    return error;
  }
};

export const authPutRequest = async (endpoint, id, data) => {
  try {
    const response = await axios.put(`${EXPRESS_API_URL}/${endpoint}/${id}`, data, { ...config });
    return response.data;
  }
  catch (error) {
    console.error('Error during PUT request:', error);
    return error;
  }
};

export const authDeleteRequest = async (endpoint, id) => {
  try {
    const response = await axios.delete(`${EXPRESS_API_URL}/${endpoint}/${id}`, { ...config });
    return response.data;
  }
  catch (error) {
    console.error('Error during DELETE request:', error);
    return error;
  }
};
