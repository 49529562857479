import React from 'react';
import { Table } from 'antd';

import { DeleteSensorForm } from '../Forms';
import { prepareAntDesignFilters } from '../../../utils';

const SensorsListTable = ({ data }) => {
  const filters = prepareAntDesignFilters(data, ['mid', 'sid', 'dataType']);

  const columns = [
    {
      title: 'Mid',
      dataIndex: 'mid',
      key: 'mid',
      width: 100,
      fixed: 'left',
      filters: filters.mid,
      onFilter: (value, record) => record.mid === value,
    },
    {
      title: 'Sid',
      dataIndex: 'sid',
      width: 100,
      key: 'sid',
      fixed: 'left',
      filters: filters.sid,
      onFilter: (value, record) => record.sid === value,
    },
    {
      title: 'Data Type',
      dataIndex: 'dataType',
      key: 'dataType',
      width: 100,
      filters: filters.dataType,
      onFilter: (value, record) => record.dataType === value,
    },
    {
      title: 'Action',
      key: 'operation',
      render: item => <DeleteSensorForm data={item} />,
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={data.map((item, index) => ({ key: index, ...item }))}
      className="min-h-screen h-full"
      scroll={{ x: 1300 }}
    />
  );
};
export default SensorsListTable;
