import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useTranslation } from 'react-i18next';

export default function ChangeLanguage({ className, showLabel = true }) {
  const { t, i18n } = useTranslation();

  const handleChange = event => {
    i18n.changeLanguage(event.target.value);
    window.location.reload();
  };

  return (
    <div className={className}>
      <FormControl fullWidth size="small">
        {showLabel && (
          <InputLabel id="demo-simple-select-autowidth-label">{t('LANGUAGE')}</InputLabel>
        )}
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={i18n.language}
          onChange={handleChange}
          label={showLabel ? t('LANGUAGE') : undefined}
        >
          <MenuItem value="tr">Türkçe</MenuItem>
          <MenuItem value="en-US">English</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
