import authHeader from '../services/auth-header';

export const config = {
  headers: {
    Authorization: authHeader().Authorization,
    'Content-Type': 'application/json',
  },
};

export const handleApiError = (error, API) => {
  if (error.response && error.response.status === 401) {
    localStorage.removeItem('user');
    window.location.reload();
  }
  // eslint-disable-next-line no-console
  console.log(`Error while calling ${API} API`, error);
};
