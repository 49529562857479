import React from 'react';

import { Alert } from 'antd';

import { NewPointIdsForm } from './Forms';

function PointIdSave() {
  return (
    <div>
      <Alert
        className="mb-4"
        message="Types are saved with the point Id value written directly, there is no validation. PointId and Types should be chosen carefully."
        type="info"
        showIcon
      />
      <NewPointIdsForm />
    </div>
  );
}

export default PointIdSave;
