import axios from 'axios';
import { call, put } from 'redux-saga/effects';

import { EXPRESS_API_URL } from '../../constants';
import { config, handleApiError } from '../utils';
import { GET_CHART_DATA_SUCCESS } from '../actions/type';

export function* getChartDataRequest(action) {
  try {
    const { mid, sid, type, dataType, last = null } = action.payload;
    let { ReadingTimeUTC = null } = action.payload;
    if (mid === undefined || sid === undefined || type === undefined || dataType === undefined) {
      return;
    }
    if ((type === 'spectrum' || type === 'timewave') && ReadingTimeUTC === null) return;
    if (type === 'trend' || type === 'heat') ReadingTimeUTC = null;
    const res = yield call(
      axios.post,
      `${EXPRESS_API_URL}/chartData/${type}`,
      { mid, sid, type, dataType, ReadingTimeUTC, last },
      config,
    );
    const sensorType = type;
    yield put({ type: GET_CHART_DATA_SUCCESS, payload: res.data, mid, sid, sensorType, dataType });
  }
  catch (error) {
    handleApiError(error, 'getChartData');
  }
}
