import { Text } from 'recharts';

export default function ReferanceLabel({ annotation, fill, ...props }) {
  const { viewBox, dy, dx } = props;
  const { x } = viewBox;
  const { y } = viewBox;
  const value = `${annotation.pieceName} ${annotation.frequencyType}`;
  const sizeOfRect = value.length * 9;
  return (
    <g width={20} height={sizeOfRect}>
      <rect x={x} y={y} dy={dy} dx={dx} fill={fill} rx={5} ry={5} width={20} height={sizeOfRect} />
      <Text
        x={x + 16}
        y={y + sizeOfRect - 5}
        fill="white"
        style={{ fontSize: 12 }}
        angle={-90}
        width={sizeOfRect}
      >
        {value}
      </Text>
    </g>
  );
}
