import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Chart } from './GetChart';
import { ChartSkeleton } from '../../shared/Skeletons';

function ChartContainer() {
  const { machine } = useParams();
  const annotations = useSelector(state => state.annotations);
  const { activeType, activeDataType, activeSensor } = useSelector(state => state.activeData);
  const chartData = useSelector(state => state.chart.data)?.[machine]?.[activeType]?.[
    activeSensor
  ]?.[activeDataType];
  const { isLoading } = useSelector(state => state.chart);

  if (isLoading || !chartData) {
    return <ChartSkeleton />;
  }

  return (
    <div>
      <Chart
        data={chartData}
        machine={machine}
        activeType={activeType}
        activeDataType={activeDataType}
        activeSensor={activeSensor}
        annotations={annotations}
      />
    </div>
  );
}

export default ChartContainer;
