import React from 'react';

import { NewUserForm } from './Forms';

function UserSave() {
  return (
    <div>
      <NewUserForm />
    </div>
  );
}

export default UserSave;
