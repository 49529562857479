import axios from 'axios';
import { call, put } from 'redux-saga/effects';

import { EXPRESS_API_URL, EXPRESS_IMAGES_URL } from '../../constants';
import { config, handleApiError } from '../utils';
import { GET_USER_DATA_SUCCESS, LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT } from '../actions/type';

export function* getUserRequest(action) {
  try {
    const { mid } = action.payload;
    const res = yield call(axios.post, `${EXPRESS_API_URL}/machines/get`, { mid }, config);
    const updatedMachines = res.data.machine.map(machine => {
      if (machine.images && machine.images.length > 0) {
        const updatedImages = machine.images.map(image => {
          return {
            ...image,
            path: image.path ? `${EXPRESS_IMAGES_URL}/${image.path}` : '',
          };
        });
        return {
          ...machine,
          images: updatedImages,
        };
      }
      return machine;
    });
    if (res.data.user.company.image && res.data.user.company.image.path) {
      res.data.user.company.image.path = `${EXPRESS_IMAGES_URL}/${res.data.user.company.image.path}`;
    }
    yield put({
      type: GET_USER_DATA_SUCCESS,
      payload: { machine: updatedMachines, user: res.data.user },
    });
  }
  catch (error) {
    handleApiError(error, 'getUser');
  }
}

export function* loginRequest(action) {
  try {
    const { email, password } = action.payload;
    const res = yield call(axios.post, `${EXPRESS_API_URL}/auth/login`, { email, password });
    const { token } = res.data.data;
    localStorage.setItem('user', JSON.stringify(token));
    yield put({ type: LOGIN_SUCCESS, payload: { user: res.data.data } });
  }
  catch (error) {
    handleApiError(error, 'login');
    yield put({ type: LOGIN_FAIL });
  }
}

export function* logout() {
  localStorage.removeItem('user');
  yield put({ type: LOGOUT });
}
