export const DATA_TYPES = {
  RMS: 'RMS',
  ACC: 'ACC',
  ENV: 'ENV',
  TEMP: 'TEMP',
};

export const TYPES = {
  TREND: 'trend',
  SPECTRUM: 'spectrum',
  TIMEWAVE: 'timewave',
  HEAT: 'heat',
};
