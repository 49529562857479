import { Line, ReferenceLine } from 'recharts';
import { useParams } from 'react-router-dom';

import { colorPalette, yAxisLabelFormatter } from '../../../utils';
import Rechart from '../../Rechart/Rechart';

export default function MultipleTempChart({ data }) {
  let { series } = data;
  const { sensorKeys } = data;
  // Temporary
  const { machine } = useParams();
  if (machine === 'UF-1A' || machine === 'UF-2B_1') {
    series = series.filter(item => {
      const itemDate = new Date(item.x);
      const cutoffDate = new Date('2024-08-13');
      return itemDate >= cutoffDate;
    });
  }
  if (machine === 'UF-2B' || machine === 'UF-2C') {
    series = series.filter(item => {
      const itemDate = new Date(item.x);
      const cutoffDate = new Date('2024-08-13');
      return itemDate < cutoffDate;
    });
  }
  // Temporary
  const options = {
    axisLabelisVisible: false,
    legendIsVisible: true,
    axisLabel: {
      x: 'Hz',
      y: yAxisLabelFormatter('TEMP', 'trend'),
    },
    xAxis: {
      type: 'datetime',
      tickCount: 7,
      tooltipType: 'date',
      minValue: series[0]?.x,
      maxValue: series[series.length - 1]?.x,
      decimalsInFloat: 0,
    },
    yAxis: {
      tickCount: 7,
      minValue: 0,
      maxValue: 80,
      decimalsInFloat: 0,
    },
    chartType: 'trend',
    multiple: true,
    legendVerticalAlign: 'top',
    legendIconType: 'circle',
    legendIconSize: 10,
    timeRangeSelector: true,
  };
  const uniqueAnomalyPoints = series
    .filter(point => point.isAnomaly)
    .map((point, index) => ({ ...point, id: `${point.x}-${index}` }));
  return (
    series && (
      <Rechart data={series} options={options}>
        {series && (
          <>
            {sensorKeys.map((key, index) => (
              <Line
                dataKey={`y${key}.value`}
                name={`Sensör ${key}`}
                type="basis"
                stroke={colorPalette[index]}
                connectNulls
                dot={false}
                key={key}
              />
            ))}
            {uniqueAnomalyPoints.map(point => (
              <ReferenceLine
                key={`anomaly-${point.id}`}
                x={point.x}
                stroke="red"
              />
            ))}
          </>
        )}
      </Rechart>
    )
  );
}
