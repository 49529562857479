import * as actionTypes from '../actions/type';

const INITIAL_STATE = {
  data: [],
  isVisible: false,
};

export const annotations = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_ANNOTATIONS:
      return { ...state, isLoading: true, data: [] };
    case actionTypes.GET_ANNOTATIONS_SUCCESS:
      return { ...state, isLoading: false, data: action.payload.data };
    case actionTypes.SHOW_HIDE_ANNOTATIONS:
      return { ...state, isVisible: action.payload.isVisible };
    default:
      return state;
  }
};
