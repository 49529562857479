/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { Tabs, Tab, TabPanel, TabsHeader, TabsBody } from '@material-tailwind/react';

import { useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import Sensors from './Sensors';
import ChartTabs from './ChartTabs';

const TypeTabs = ({ DATA_TYPES, dataTypes, changeType, children }) => {
  const { machine } = useParams();
  const { t } = useTranslation();
  const { activeDataType } = useSelector(state => state.activeData);
  const liveStatus = useSelector(state => state.liveStatus)[machine];
  return (
    <Tabs key="typeTabs" value={dataTypes[activeDataType].title} className="mt-3 h-min[350px]">
      <TabsHeader
        key="typeTabsHeader"
        className="p-0 h-10"
        indicatorProps={{ className: 'shadow-none' }}
      >
        {Object.keys(DATA_TYPES).map(dataType => {
          const isAnomalyExist =
            liveStatus !== undefined && dataType !== 'TEMP'
              ? Object?.values(liveStatus?.[dataType])?.includes(true)
              : false;
          if ((machine === '12-Q9' || machine === '12-Q10') && dataType === 'TEMP') return null;
          return (
            <Tab
              className={
                activeDataType === dataType
                  ? 'font-black bg-white h-[58px] rounded-md rounded-b-none border-b-none rounded-t-md border-t-2 border-l-2 border-r-2'
                  : 'bg-white'
              }
              key={`Tab_${dataTypes[dataType].title}`}
              value={dataTypes[dataType].title}
              onClick={() => changeType(dataType)}
            >
              <div className="flex items-center">
                <div
                  className={`${
                    isAnomalyExist ? 'bg-orange-500' : 'bg-green-500'
                  } h-5 w-5 rounded-full animate-pulse mr-2`}
                />
                <div className="flex flex-col">
                  <span>{t(dataTypes[dataType].title)}</span>
                  <span className="opacity-50 text-sm">
                    {activeDataType === dataType &&
                      (dataTypes[dataType].yaxisTitle?.trend ||
                        dataTypes[dataType].yaxisTitle?.heat)}
                  </span>
                </div>
              </div>
            </Tab>
          );
        })}
      </TabsHeader>
      <div className="border-2 rounded-md mt-4">
        <TabsBody key="typeTabsBody" className="border-x-0 border-b-2 rounded pt-4 min-h-[720px]">
          {Object.keys(DATA_TYPES).map(dataType => (
            <TabPanel
              className="mt-4"
              key={dataTypes[dataType].title}
              value={dataTypes[dataType].title}
            >
              <Sensors dataType={dataType} />
            </TabPanel>
          ))}
          <ChartTabs />
        </TabsBody>
        {children}
      </div>
    </Tabs>
  );
};

export default TypeTabs;
