import { CircularProgress } from '@mui/material';
import React from 'react';

export default function FullPageLoading() {
  return (
    <div className="absolute left-0 top-0 w-screen h-screen bg-white z-50 flex justify-center items-center">
      <CircularProgress size="5rem" />
    </div>
  );
}
