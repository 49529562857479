import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { login } from '../../redux/actions/index';
import ChangeLanguage from '../shared/ChangeLanguage';

export function Login() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isLoggedIn, isLoading } = useSelector(state => {
    return state.auth;
  });
  const mailOrPasswordWrong = useSelector(state => {
    return state.auth.mailOrPasswordWrong;
  });
  const [values, setValues] = useState({
    email: '',
    password: '',
  });
  const handleEmailChange = event => {
    event.persist();
    setValues(value => ({
      ...value,
      email: event.target.value,
    }));
  };
  const handleLogin = event => {
    event.preventDefault();
    event.persist();
    dispatch(login(values.email, values.password));
  };
  const handlePasswordChange = event => {
    event.persist();
    setValues(value => ({
      ...value,
      password: event.target.value,
    }));
  };

  if (!isLoading && isLoggedIn) {
    window.location = '/';
  }

  return (
    <div className="h-screen w-screen flex items-center justify-center">
      <ChangeLanguage className="w-32 absolute top-4 right-4" />
      <div className="container mx-auto grid grid-cols-1 justify-center px-5">
        <div className="bg-primary mx-auto mb-4 fill-white h-11 w-11 p-2 rounded-full flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            className="h-11 w-11"
            version="1.1"
            viewBox="0 0 80.81 57.76"
            xmlSpace="preserve"
          >
            {/* eslint-disable-next-line max-len */}
            <path d="M80.81 1.23c-.16.2-.27.34-.4.49-6.5 7.72-13.01 15.44-19.5 23.17-5.04 6.01-10.07 12.03-15.11 18.05-.86 1.02-1.74 2.02-2.58 3.06-.44.54-.77.66-1.35.12-4.96-4.63-10.3-8.82-15.49-13.18-.66-.55-.47-.94-.06-1.47.42-.54.72-.66 1.34-.13 4.61 3.93 9.27 7.79 13.89 11.71.63.54.91.68 1.55-.1 5.8-6.97 11.67-13.88 17.51-20.82 5.86-6.97 11.7-13.95 17.55-20.93.22-.26.44-.53.71-.75.19-.16.24-.68.67-.35.44.35.84.75 1.27 1.13zM4.76 53.51c-.11.54-.32 1.01-.99 1.01-.65 0-1-.44-1.01-1-.01-.53.39-.97.92-.98.55 0 1 .31 1.08.97zM37.99 14.04c-.14.62-.53 1.02-1.11.98-.51-.03-.94-.43-.92-.98.02-.57.36-1.03 1-1.01.63.03.91.47 1.03 1.01zM35.23 17.34c-.2.6-.48 1.05-1.14.95-.54-.08-.93-.4-.88-.98.05-.59.44-.98 1.05-.96.63.02.86.5.97.99zM6.53 51.27c-.59-.14-1.03-.47-.99-1.07.04-.57.4-1.01 1.05-.95.54.05.93.37.91.96-.02.61-.41.94-.97 1.06zM28.64 22.88c.58.08.97.42.98 1.02.01.56-.35.95-.9.98-.58.03-1.01-.26-1.04-.92-.01-.64.35-.95.96-1.08zM43.3 18.51c.88.01 1.17.34 1.17.92 0 .64-.32.98-.98.95-.59-.02-.89-.33-.9-.92-.02-.67.37-.93.71-.95zM18.54 37.08c-.01.61-.32.95-.97.94-.59-.01-.9-.33-.91-.91-.01-.57.24-.94.85-.97.62-.03 1 .24 1.03.94zM13.02 43.71c-.02.52-.33.94-.95.93-.59-.01-.96-.38-.96-1.02 0-.6.4-.94.9-.96.55-.02 1.01.28 1.01 1.05zM25.95 28.17c-.62-.04-1-.33-1.01-.98-.01-.6.33-.9.91-.92.55-.02.97.18.99.81.01.61-.23 1.02-.89 1.09zM23.3 31.42c-.81-.01-1.14-.33-1.12-.99.02-.61.39-.89.94-.9.65-.01.96.37.93 1.01-.01.59-.3.91-.75.88zM.88 57.76c-.57.02-.87-.4-.88-.92-.01-.6.35-.99 1.02-.96.59.03.96.36.95.92-.01.58-.36.99-1.09.96zM14.97 41.32c-.68-.04-1.08-.22-1.08-.87 0-.63.28-1.04.92-1.05.62-.01.96.37.97 1.01 0 .63-.43.81-.81.91z" />
            {/* eslint-disable-next-line max-len */}
            <path d="M46.87 23.16c-.63 0-1.03-.34-1.02-.94 0-.5.26-.93.89-.95.64-.03 1.04.3 1.08.89.04.58-.31.97-.95 1zM41.23 16.75c-.15.56-.43.94-1.1.87-.61-.06-.86-.47-.84-1 .02-.64.43-.92 1.06-.87.61.04.84.43.88 1zM21.3 33.54c0 .86-.35 1.24-.95 1.23-.51-.01-.92-.28-.94-.9-.02-.64.31-1.04.9-1.06.57-.03 1.02.27.99.73zM10.28 46.98c-.14.56-.4.95-1.08.9-.6-.05-.9-.38-.86-.95.04-.61.35-.97 1.03-.93.61.05.84.42.91.98zM32.43 20.56c-.1.59-.31 1.04-1.01 1-.6-.03-.95-.35-.95-.92-.01-.63.38-.97 1.02-.97.6-.01.85.37.94.89zM51.1 25.06c-.08.64-.49.88-1.1.84-.61-.04-.85-.41-.81-.98.04-.56.33-.92.93-.89.64.02.94.4.98 1.03zM53.74 28.66c-.9 0-1.28-.26-1.28-.93 0-.6.35-.91.9-.94.65-.04.97.34.99.96.01.58-.25.94-.61.91z" />
          </svg>
        </div>
        <div className="lg:w-[41%] md:w-6/12 sm:w-full border border-slate-200 bg-white rounded-xl mx-auto">
          <div className="py-3 px-5 text-center text-sm border-b border-slate-200">
            {t('SIGN_IN_TO_PDMECHANICS')}
          </div>
          <form className="p-3 grid grid-cols-1 gap-3" onSubmit={handleLogin}>
            {mailOrPasswordWrong && (
              <div className="bg-red-50 text-red-500 p-3 rounded-lg text-xs">
                <p>{t('INCORRECT_PASSWORD_OR_EMAIL')}</p>
              </div>
            )}
            <div className="relative">
              <input
                required=""
                name="email"
                type="email"
                id="email"
                className="block rounded-lg px-3 pb-2.5 pt-5 w-full text-sm text-gray-900
                 bg-transparent border border-slate-200 appearance-none focus:outline-none focus:ring-0 focus:border-primary peer"
                placeholder=" "
                onChange={handleEmailChange}
                value={values.email}
              />
              <label
                htmlFor="email"
                className="absolute text-sm text-gray-500 duration-300 transform
                 -translate-y-3 scale-75 top-4 z-10 origin-[0] left-3 peer-focus:text-primary
                  peer-focus:dark:text-primary peer-placeholder-shown:scale-100
                   peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-3"
              >
                {t('EMAIL_ADDRESS')}
              </label>
            </div>
            <div className="relative">
              <input
                required=""
                name="password"
                type="password"
                id="password"
                className="block rounded-lg px-3 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-transparent border border-slate-200
                 appearance-none focus:outline-none focus:ring-0 focus:border-primary peer"
                placeholder=" "
                onChange={handlePasswordChange}
                value={values.password}
              />
              <label
                htmlFor="password"
                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-3
                 scale-75 top-4 z-10 origin-[0] left-3 peer-focus:text-primary peer-focus:dark:text-primary
                  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-3"
              >
                {t('PASSWORD')}
              </label>
            </div>
            <p className="text-xs font-light">
              {t('SECURITY_INFO')}
              &nbsp;
              <nuxt-link to="/" class="font-medium">
                {t('PRIVACY_POLICY')}
              </nuxt-link>
            </p>
            <div className="flex justify-center">
              {isLoading ? (
                <CircularProgress className="!mx-auto" size="2.5rem" />
              ) : (
                <button
                  type="submit"
                  className="w-full h-12 bg-primary text-white border border-primary rounded-xl text-sm
              hover:bg-white hover:text-primary hover:shadow-xl transition duration-300"
                >
                  {t('LOGIN')}
                </button>
              )}
            </div>
            <div className="text-center font-light text-xs">
              <p>
                {t('FORGOT_PASSWORD')} <span className="font-medium">{t('RESET_PASSWORD')}</span>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
