import { Select, Option } from '@material-tailwind/react';
import { useDispatch, useSelector } from 'react-redux';

import { useEffect, useState } from 'react';
import { LinearProgress } from '@mui/material';
import { useParams } from 'react-router-dom';

import moment from 'moment';

import { getChartData, getTimeOptions, setTimeOption } from '../../../redux/actions';

function TimeOptions() {
  const dispatch = useDispatch();
  const { machine } = useParams();
  const { timeOptions } = useSelector(state => state?.timeoptions);
  const [selected, setSelected] = useState();
  const { activeSensor, activeType, activeDataType } = useSelector(state => state.activeData);
  useEffect(() => {
    dispatch(getTimeOptions(machine, activeSensor, activeType, activeDataType));
  }, [dispatch, machine, activeSensor, activeType, activeDataType]);

  useEffect(() => {
    if (selected !== undefined) {
      dispatch(getChartData(machine, activeSensor, activeType, activeDataType, selected));
    }
  }, [dispatch, machine, activeSensor, activeType, activeDataType, selected]);

  const handleChange = value => {
    setSelected(value);
  };

  useEffect(() => {
    if (selected === undefined) {
      handleChange(timeOptions?.at(0)?.ReadingTimeUTC);
    }
  }, [timeOptions, selected]);

  return (
    <div>
      {selected !== undefined ? (
        <Select value={selected} onChange={handleChange}>
          {timeOptions.map(option => (
            <Option
              key={`Option_${option.ReadingTimeUTC}`}
              onClick={() => {
                dispatch(setTimeOption(option.ReadingTimeUTC));
              }}
              value={option.ReadingTimeUTC}
            >
              {moment.utc(option.ReadingTimeUTC).add(3, 'hours').format('YYYY-MM-DD HH:mm:ss')}
            </Option>
          ))}
        </Select>
      ) : (
        <LinearProgress />
      )}
    </div>
  );
}

export default TimeOptions;
