/* eslint-disable no-unused-vars */
import { Button, Form, Input, Popconfirm, Upload, message } from 'antd';
import React, { useState } from 'react';
import {
  DeleteOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from '@ant-design/icons';

import {
  authDeleteRequest,
  authPostRequest,
  authPutRequest,
} from '../../../../services/api-service';
import { uploadConfig } from '../../utils';

export function NewCompanyForm() {
  const [messageApi, contextHolder] = message.useMessage();
  const [imageId, setImageId] = useState();
  const onFinish = async data => {
    const requestData = { ...data, image: imageId };
    const response = await authPostRequest('companies/new', requestData);
    if (response.statusCode === 200) {
      messageApi.open({
        type: 'success',
        content: 'Company has been added.',
      });
    }
    else {
      messageApi.open({
        type: 'error',
        content: response.message || response.statusCode,
      });
    }
  };
  const handleImagesChange = info => {
    if (info.file.status === 'done') {
      const { originalname, path, _id } = info.file.response.data;
      setImageId(_id);
      message.success(`${originalname} file uploaded to ${path} successfully`);
    }
    else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };
  const handleImageRemove = async info => {
    const id = info.response.data._id;
    await authDeleteRequest('media', id);
  };
  return (
    <Form
      name="newCompanyForm"
      onFinish={onFinish}
      className="lg:w-1/3 w-full flex flex-col justify-center overflow-hidden"
    >
      <Form.Item
        label="Company"
        name="company"
        rules={[
          {
            required: true,
            message: 'Please input company name',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.List name="factories">
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map(field => (
              <Form.Item label="Factory" required={false} key={field.key}>
                <Form.Item
                  {...field}
                  validateTrigger={['onChange', 'onBlur']}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: 'Factory Name',
                    },
                  ]}
                  noStyle
                >
                  <Input placeholder="Factory Name" style={{ width: '60%' }} />
                </Form.Item>
                {fields.length > 1 ? (
                  <MinusCircleOutlined
                    className="dynamic-delete-button"
                    onClick={() => remove(field.name)}
                  />
                ) : null}
              </Form.Item>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                style={{ width: '60%' }}
                icon={<PlusOutlined />}
              >
                Add Factory
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>
      <Form.Item label="Images">
        <Upload
          {...uploadConfig}
          name="file"
          multiple
          maxCount={5}
          listType="picture"
          onChange={handleImagesChange}
          onRemove={handleImageRemove}
        >
          <Button icon={<UploadOutlined />}>Upload (Max: 1)</Button>
        </Upload>
      </Form.Item>
      <Button type="primary" htmlType="submit">
        Submit
      </Button>
      {contextHolder}
    </Form>
  );
}

export function EditCompanyForm({ editData }) {
  const defaultImages = editData?.image?._id
    ? [
      {
        ui: 1,
        _id: editData.image._id,
        name: editData.image.filename,
        url: editData.image.path,
        thumbUrl: editData.image.path,
        status: 'done',
      },
    ]
    : [];
  const [messageApi, contextHolder] = message.useMessage();
  const [imageId, setImageId] = useState();
  const onFinish = async data => {
    const requestData = { ...data, image: imageId };
    const response = await authPutRequest('companies/update', editData._id, requestData);
    if (response.statusCode === 200) {
      messageApi.open({
        type: 'success',
        content: 'Company has been updated.',
      });
    }
    else {
      messageApi.open({
        type: 'error',
        content: response.message || response.statusCode,
      });
    }
  };
  const handleImagesChange = info => {
    if (info.file.status === 'done') {
      const { originalname, path, _id } = info.file.response.data;
      setImageId(_id);
      message.success(`${originalname} file uploaded to ${path} successfully`);
    }
    else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };
  const handleImageRemove = async info => {
    const id = info._id;
    await authDeleteRequest('media', id);
  };
  return (
    <Form
      name="editCompanyForm"
      onFinish={onFinish}
      initialValues={editData}
      className="lg:w-1/3 w-full flex flex-col justify-center overflow-hidden"
    >
      <Form.Item
        label="Company"
        name="company"
        rules={[
          {
            required: true,
            message: 'Please input company name',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Image">
        <Upload
          {...uploadConfig}
          name="file"
          multiple
          maxCount={5}
          listType="picture"
          onChange={handleImagesChange}
          onRemove={handleImageRemove}
          defaultFileList={defaultImages}
        >
          <Button icon={<UploadOutlined />}>Upload (Max: 1)</Button>
        </Upload>
      </Form.Item>
      <Form.List name="factories">
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map(field => (
              <Form.Item label="Factory" required={false} key={field.key}>
                <Form.Item
                  {...field}
                  validateTrigger={['onChange', 'onBlur']}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: 'Factory Name',
                    },
                  ]}
                  noStyle
                >
                  <Input placeholder="Factory Name" style={{ width: '60%' }} />
                </Form.Item>
                {fields.length > 1 ? (
                  <MinusCircleOutlined
                    className="dynamic-delete-button"
                    onClick={() => remove(field.name)}
                  />
                ) : null}
              </Form.Item>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                style={{ width: '60%' }}
                icon={<PlusOutlined />}
              >
                Add Factory
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>
      <Button type="primary" htmlType="submit">
        Submit
      </Button>
      {contextHolder}
    </Form>
  );
}

export function DeleteCompanyForm({ data }) {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const showPopconfirm = () => {
    setOpen(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);
    const response = await authDeleteRequest('companies/delete', data._id);
    if (response.statusCode === 200) {
      messageApi.open({
        type: 'success',
        content: 'Company has been deleted.',
      });
      setOpen(false);
      setConfirmLoading(false);
    }
    else {
      messageApi.open({
        type: 'error',
        content: response.message || response.statusCode,
      });
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };
  return (
    <Popconfirm
      title="Delete the company"
      description="Are you sure to delete this company?"
      open={open}
      onConfirm={handleOk}
      okButtonProps={{ loading: confirmLoading }}
      onCancel={handleCancel}
    >
      <Button danger onClick={showPopconfirm}>
        <DeleteOutlined />
      </Button>
      {contextHolder}
    </Popconfirm>
  );
}
