import React, { useEffect, useState } from 'react';
import { Layout, Menu, theme } from 'antd';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import { menuItems } from './constants';

const { Header, Content, Footer, Sider } = Layout;

export const Admin = () => {
  const { token: { colorBgContainer, borderRadiusLG } } = theme.useToken();
  const [currentPage, setCurrentPage] = useState(menuItems[0].key);
  const navigate = useNavigate();
  const changeActiveItem = item => {
    navigate(`/admin/${item.key}/list`);
  };
  const location = useLocation();
  useEffect(() => {
    const activeKey = location.pathname.split('/')[2];
    setCurrentPage(activeKey);
  }, [location]);
  return (
    <Layout className="h-100 min-h-screen">
      <Header
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <img src="/assets/logo.png" alt="logo" width={100} height={50} />
        <button type="button" className="ml-auto text-white" onClick={() => navigate('/')}>
          <span className="mr-1">Platform</span>
          <ExitToAppIcon />
        </button>
      </Header>
      <Content>
        <Layout
          style={{
            padding: '24px 0',
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <Sider breakpoint="lg" style={{ background: colorBgContainer }} width={200}>
            <Menu
              mode="inline"
              selectedKeys={[currentPage]}
              style={{ height: '100%' }}
              items={menuItems}
              onClick={changeActiveItem}
            />
          </Sider>
          <Content
            style={{
              padding: '0 24px',
              minHeight: '90vh',
              overflow: 'hidden',
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Content>
      <Footer style={{ textAlign: 'center' }}>
        Pdmechanics ©{new Date().getFullYear()} Created by Pdmechanics Development
      </Footer>
    </Layout>
  );
};
